<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card title="Lista de empresas">
          <div class="d-flex justify-content-between flex-wrap my-2">
            <!-- filter -->
            <b-form-group
              label="Buscador"
              label-cols-sm="3"
              label-align-sm="left"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  placeholder="Buscador"
                  type="search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Limpiar
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <b-table
            responsive
            hover
            show-empty
            bordered
            :small="true"
            :items="itemsEmpresa"
            :fields="fieldsEmpresa"
            :filter="filter"
            :filter-included-fields="filterOn"
            empty-filtered-text="No se encontro ningun resultado"
            sticky-header="200px"
            select-mode="single"
            selectable
            @row-selected="onEmpresaSelect"
          >
            <template #empty>
              <h4 class="text-center my-2">
                No tiene información para mostrar
              </h4>
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
                <strong class="ml-1">Cargando ...</strong>
              </div>
            </template>
            <template #head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template #cell()="data">
              <span class="text-nowrap">{{ data.value }}</span>
            </template>
            <template #cell(acciones)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="idEmpresa > 0">
      <b-col
        cols="12"
      >
        <b-tabs v-model="tabIndex">
          <b-tab title="Administrador">
            <b-card title="Datos del contacto">
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label="Nombre"
                    label-for="nombre-contacto"
                  >
                    <b-form-input
                      id="nombre-contacto"
                      v-model="empresa.nombre"
                      placeholder="Ingresar nombre"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Apellido paterno"
                    label-for="paterno-contacto"
                  >
                    <b-form-input
                      id="paterno-contacto"
                      v-model="empresa.apellidoPaterno"
                      placeholder="Ingresar apellido paterno"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Apellido materno"
                    label-for="materno-contacto"
                  >
                    <b-form-input
                      id="materno-contacto"
                      v-model="empresa.apellidoMaterno"
                      placeholder="Ingresar apellido materno"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label="Area"
                    label-for="area-contacto"
                  >
                    <b-form-input
                      id="area-contacto"
                      v-model="empresa.area"
                      placeholder="Ingresar area"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Tipo documento"
                    label-for="documento-contacto"
                  >
                    <b-form-input
                      id="tipo-documento-contacto"
                      v-model="empresa.tipoDocumento"
                      placeholder="Ingresar apellido paterno"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Nro documento"
                    label-for="numero-documento-contacto"
                  >
                    <b-form-input
                      id="numero-documento-contacto"
                      v-model="empresa.numeroDocumento"
                      placeholder="Ingresar apellido materno"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label="Telefono"
                    label-for="telefono-contacto"
                  >
                    <b-form-input
                      id="telefono-contacto"
                      v-model="empresa.telefono"
                      placeholder="Ingresar telefono"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="8">
                  <b-form-group
                    label="Email"
                    label-for="email-contacto"
                  >
                    <b-form-input
                      id="materno-contacto"
                      v-model="empresa.email"
                      placeholder="Ingresar email"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-if="$can('empresaCrear', 'empresaCrear')"
                  md="4"
                >
                  <b-form-group
                    label="Cantidad maxima de licencias"
                    label-for="cantidad-maxima"
                  >
                    <b-form-input
                      id="cantidad-maxima"
                      v-model="empresa.cantidadMaxima"
                      placeholder="Ingresar cantidad"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-else
                  md="4"
                >
                  <b-form-group
                    label="Cantidad maxima de licencias"
                    label-for="cantidad-maxima"
                  >
                    <b-form-input
                      id="cantidad-maxima"
                      v-model="empresa.cantidadMaxima"
                      readonly
                      placeholder="Ingresar cantidad"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Licencias en uso"
                    label-for="licencia-uso"
                  >
                    <b-form-input
                      id="licencia-uso"
                      v-model="empresa.licenciaUso"
                      readonly
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-card>
                    <b-card-title>Usuarios administradores</b-card-title>

                    <b-row>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <div class="d-flex justify-content-between flex-wrap my-2">
                          <!-- filter -->
                          <b-form-group
                            label="Buscador"
                            label-cols-sm="3"
                            label-align-sm="left"
                            label-for="filterInput"
                            class="mb-0"
                          >
                            <b-input-group size="sm">
                              <b-form-input
                                id="filterInput"
                                v-model="filterNoAdministrador"
                                placeholder="Buscador"
                                type="search"
                              />
                              <b-input-group-append>
                                <b-button
                                  :disabled="!filterNoAdministrador"
                                  @click="filterNoAdministrador = ''"
                                >
                                  Limpiar
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </div>
                        <b-table
                          responsive
                          striped
                          hover
                          show-empty
                          bordered
                          sticky-header
                          :small="true"
                          :items="itemsNoAdministrador"
                          :fields="fieldsNoAdministrador"
                          :filter="filterNoAdministrador"
                          :filter-included-fields="filterOnNoAdministrador"
                          empty-filtered-text="No se encontro ningun resultado"
                          empty-text=" "
                        >
                          <template #empty>
                            <h4 class="text-center my-2">
                              No tiene información para mostrar
                            </h4>
                          </template>
                          <template #emptyfiltered="scope">
                            <h4 class="text-center my-2">
                              {{ scope.emptyFilteredText }}
                            </h4>
                          </template>
                          <template #table-busy>
                            <div class="text-center my-2">
                              <b-spinner class="align-middle" />
                              <strong class="ml-1">Cargando ...</strong>
                            </div>
                          </template>
                          <template #head()="scope">
                            <div class="text-nowrap">
                              {{ scope.label }}
                            </div>
                          </template>
                          <template #cell()="data">
                            <span class="text-nowrap">{{ data.value }}</span>
                          </template>
                          <template #cell(acciones)="row">
                            <b-button
                              v-show="$can('empresaActualizar', 'empresaActualizar')"
                              variant="gradient-success"
                              class="btn-icon mr-50"
                              size="sm"
                              @click="areaAgregarUsuario(row.item.id)"
                            >
                              <feather-icon icon="EditIcon" />
                            </b-button>
                          </template>
                        </b-table>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <div class="d-flex justify-content-between flex-wrap my-2">
                          <!-- filter -->
                          <b-form-group
                            label="Buscador"
                            label-cols-sm="3"
                            label-align-sm="left"
                            label-for="filterInput"
                            class="mb-0"
                          >
                            <b-input-group size="sm">
                              <b-form-input
                                id="filterInput"
                                v-model="filterAdministrador"
                                placeholder="Buscador"
                                type="search"
                              />
                              <b-input-group-append>
                                <b-button
                                  :disabled="!filterAdministrador"
                                  @click="filterAdministrador = ''"
                                >
                                  Limpiar
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </div>
                        <b-table
                          responsive
                          striped
                          hover
                          show-empty
                          bordered
                          sticky-header
                          :small="true"
                          :items="itemsAdministrador"
                          :fields="fieldsAdministrador"
                          :filter="filterAdministrador"
                          :filter-included-fields="filterOnAdministrador"
                          empty-filtered-text="No se encontro ningun resultado"
                          empty-text=" "
                        >
                          <template #empty>
                            <h4 class="text-center my-2">
                              No tiene información para mostrar
                            </h4>
                          </template>
                          <template #emptyfiltered="scope">
                            <h4 class="text-center my-2">
                              {{ scope.emptyFilteredText }}
                            </h4>
                          </template>
                          <template #table-busy>
                            <div class="text-center my-2">
                              <b-spinner class="align-middle" />
                              <strong class="ml-1">Cargando ...</strong>
                            </div>
                          </template>
                          <template #head()="scope">
                            <div class="text-nowrap">
                              {{ scope.label }}
                            </div>
                          </template>
                          <template #cell()="data">
                            <span class="text-nowrap">{{ data.value }}</span>
                          </template>
                          <template #cell(acciones)="row">
                            <b-button
                              v-show="$can('empresaEliminar', 'empresaEliminar')"
                              variant="gradient-danger"
                              class="btn-icon"
                              size="sm"
                              @click="areaEliminarUsuario(row.item.id)"
                            >
                              <feather-icon icon="XIcon" />
                            </b-button>
                          </template>
                        </b-table>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
          <b-tab title="Propiedades">
            <b-card title="Datos de area">
              <div class="d-flex justify-content-between flex-wrap my-2">
                <!-- filter -->
                <b-form-group
                  label="Buscador"
                  label-cols-sm="3"
                  label-align-sm="left"
                  label-for="filterInput"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="filterArea"
                      placeholder="Buscador"
                      type="search"
                    />
                    <b-input-group-append>
                      <b-button
                        :disabled="!filterArea"
                        @click="filterArea = ''"
                      >
                        Limpiar
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
              <b-table
                responsive
                striped
                hover
                show-empty
                bordered
                sticky-header
                :small="true"
                :busy="cargandoArea"
                :items="itemsArea"
                :fields="fieldsArea"
                :filter="filterArea"
                :filter-included-fields="filterOnArea"
                empty-filtered-text="No se encontro ningun resultado"
                empty-text=" "
              >
                <template #empty>
                  <h4 class="text-center my-2">
                    No tiene información para mostrar
                  </h4>
                </template>
                <template #emptyfiltered="scope">
                  <h4 class="text-center my-2">
                    {{ scope.emptyFilteredText }}
                  </h4>
                </template>
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle" />
                    <strong class="ml-1">Cargando ...</strong>
                  </div>
                </template>
                <template #head()="scope">
                  <div class="text-nowrap">
                    {{ scope.label }}
                  </div>
                </template>
                <template #cell()="data">
                  <span class="text-nowrap">{{ data.value }}</span>
                </template>
                <template #cell(camp_empr)="row">
                  <b-button
                    class="mr-1"
                    variant="relief-secondary"
                    size="sm"
                    @click="obtenerCampana(row.item.id, true)"
                  >
                    Campaña
                  </b-button>
                </template>
                <template #cell(usua_empr)="row">
                  <b-button
                    class="mr-1"
                    variant="relief-secondary"
                    size="sm"
                    @click="obtenerUsuario(row.item.id, true)"
                  >
                    Usuario
                  </b-button>
                </template>
                <template #cell(ince_empr)="row">
                  <b-button
                    class="mr-1"
                    variant="relief-secondary"
                    size="sm"
                    @click="obtenerIncentivo(row.item.id)"
                  >
                    Incentivos
                  </b-button>
                </template>
                <template #cell(acciones)="row">
                  <b-button
                    v-show="$can('empresaActualizar', 'empresaActualizar')"
                    variant="gradient-success"
                    class="btn-icon mr-50"
                    size="sm"
                    @click="modalArea(row.item.id)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    v-show="$can('empresaEliminar', 'empresaEliminar')"
                    variant="gradient-danger"
                    class="btn-icon"
                    size="sm"
                    @click="eliminarArea(row.item.id)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </template>
              </b-table>
            </b-card>
            <b-row>
              <b-col>
                <b-button
                  v-show="$can('empresaCrear', 'empresaCrear')"
                  variant="primary"
                  @click="modalArea(0)"
                >
                  <feather-icon
                    icon="FileIcon"
                    class="mr-50"
                  />
                  <span>Agregar area</span>
                </b-button>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <b-modal
      v-model="popupAgregarArea"
      hide-footer
      scrollable
      no-close-on-backdrop
      size="lg"
      :title="tituloArea"
      @hidde="limpiarArea()"
    >
      <b-row>
        <b-col>
          <div class="d-flex align-items-center justify-content-end mb-2">
            <b-button
              variant="primary"
              class="ml-1"
              @click="registrarArea()"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50 text-white"
              />
              <span class="text-white">Registrar</span>
            </b-button>
            <b-button
              variant="danger"
              class="ml-1"
              @click="limpiarArea()"
            >
              <feather-icon
                icon="FileIcon"
                class="mr-50"
              />
              Limpiar
            </b-button>
          </div>
        </b-col>
      </b-row>
      <p class="text-right">
        <small class="text-muted">Los campos marcados con * son obligatorios</small>
      </p>
      <validation-observer ref="filterArea">
        <b-row>
          <b-col>
            <b-form-group
              label-for="nombre"
              label="Area *"
            >
              <validation-provider
                #default="{ errors }"
                mode="passive"
                name="nombre"
                rules="required"
              >
                <b-form-input
                  id="nombre"
                  ref="nombre"
                  v-model="formArea.nombre"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Ingresar nombre"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              label-for="estado"
              label="Estado *"
            >
              <validation-provider
                #default="{ errors }"
                mode="passive"
                name="estado"
                rules="required"
              >
                <b-form-select
                  id="estado"
                  ref="estado"
                  v-model="formArea.estado"
                  :options="optionsEstado"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
    <b-modal
      v-model="popupCampana"
      hide-footer
      scrollable
      no-close-on-backdrop
      centered
      size="lg"
      title="Detalle campaña"
    >
      <b-row>
        <b-col>
          <div class="d-flex align-items-center justify-content-end mb-2">
            <b-button
              v-show="$can('empresaCrear', 'empresaCrear')"
              variant="primary"
              class="ml-1"
              @click="modalCampana(0)"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50 text-white"
              />
              <span class="text-white">Agregar campaña</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-table
        responsive
        striped
        hover
        show-empty
        bordered
        sticky-header
        :small="true"
        :busy="cargandoCampana"
        :items="itemsCampana"
        :fields="fieldsCampana"
        :filter="filterCampana"
        :filter-included-fields="filterOnCampana"
        empty-filtered-text="No se encontro ningun resultado"
        empty-text=" "
      >
        <template #empty>
          <h4 class="text-center my-2">
            No tiene información para mostrar
          </h4>
        </template>
        <template #emptyfiltered="scope">
          <h4 class="text-center my-2">
            {{ scope.emptyFilteredText }}
          </h4>
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
            <strong class="ml-1">Cargando ...</strong>
          </div>
        </template>
        <template #head()="scope">
          <div class="text-nowrap">
            {{ scope.label }}
          </div>
        </template>
        <template #cell()="data">
          <span class="text-nowrap">{{ data.value }}</span>
        </template>
        <template #cell(metr_camp)="row">
          <b-button
            class="mr-1"
            variant="relief-secondary"
            size="sm"
            @click="obtenerMetrica(row.item.id)"
          >
            <feather-icon
              icon="EyeIcon"
              class="mr-50"
            /> Métrica
          </b-button>
        </template>
        <template #cell(acciones)="row">
          <b-button
            v-show="$can('empresaActualizar', 'empresaActualizar')"
            :id="`modalCampana${row.item.id}`"
            variant="gradient-success"
            class="btn-icon mr-50"
            size="sm"
            @click="modalCampana(row.item.id)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            v-show="$can('empresaEliminar', 'empresaEliminar')"
            :id="`eliminarCampana${row.item.id}`"
            variant="gradient-danger"
            class="btn-icon"
            size="sm"
            @click="eliminarCampana(row.item.id)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
          <b-tooltip
            :target="`modalCampana${row.item.id}`"
            title="Editar campaña"
          />
          <b-tooltip
            :target="`eliminarCampana${row.item.id}`"
            title="Eliminar campaña"
          />
        </template>
      </b-table>

    </b-modal>
    <b-modal
      v-model="popupAgregarCampana"
      hide-footer
      scrollable
      no-close-on-backdrop
      size="xl"
      :title="tituloCampana"
      @hidde="limpiarCampana()"
    >
      <div style="min-height: 450px;">
        <b-row>
          <b-col>
            <p class="text-right">
              <small class="text-muted">Los campos marcados con * son obligatorios</small>
            </p>
            <b-row>
              <b-col>
                <validation-observer ref="filterCampana">
                  <b-tabs
                    v-model="tabIndexCampana"
                    justified
                  >
                    <b-tab
                      title="Generales"
                      active
                    >
                      <b-row>
                        <b-col>
                          <b-form-group
                            label-for="nombre"
                            label="Nombre *"
                          >
                            <validation-provider
                              #default="{ errors }"
                              mode="passive"
                              name="nombre"
                              rules="required"
                            >
                              <b-form-input
                                id="nombre"
                                ref="nombre"
                                v-model="formCampana.nombre"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Ingresar nombre"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="4">
                          <b-form-group
                            label-for="reinicio"
                            label="Reinicio *"
                          >
                            <validation-provider
                              #default="{ errors }"
                              mode="passive"
                              name="reinicio"
                              rules="required"
                            >
                              <b-form-select
                                id="reinicio"
                                ref="reinicio"
                                v-model="formCampana.reinicio"
                                :options="optionsReinicio"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group
                            label-for="mostrar"
                            label="Mostrar *"
                          >
                            <validation-provider
                              #default="{ errors }"
                              mode="passive"
                              name="mostrar"
                              rules="required"
                            >
                              <b-form-select
                                id="mostrar"
                                ref="mostrar"
                                v-model="formCampana.mostrar"
                                :options="optionsMostrar"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          v-if="formCampana.mostrar=='2'"
                          md="4"
                        >
                          <b-form-group
                            label-for="total"
                            label="Total campaña"
                          >
                            <b-form-input
                              id="total"
                              ref="total"
                              v-model="formCampana.totalProducto"
                              placeholder="Ingresar total"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="12">
                          <div class="custom-checkbox-group">
                            <b-form-checkbox
                              v-model="formCampana.chatSoporte"
                              value="1"
                              unchecked-value="0"
                              class="bv-check-inline custom-checkbox"
                            >
                              Chat soporte
                            </b-form-checkbox>
                            <b-form-checkbox
                              v-model="formCampana.trivia"
                              value="1"
                              unchecked-value="0"
                              class="bv-check-inline custom-checkbox"
                            >
                              Trivia
                            </b-form-checkbox>
                            <b-form-checkbox
                              v-model="formCampana.objetivoDiario"
                              value="1"
                              unchecked-value="0"
                              class="bv-check-inline custom-checkbox"
                            >
                              Objetivo diario
                            </b-form-checkbox>
                            <b-form-checkbox
                              v-model="formCampana.avanceComisiones"
                              value="1"
                              unchecked-value="0"
                              class="bv-check-inline custom-checkbox"
                            >
                              Avance de comisiones
                            </b-form-checkbox>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="4">
                          <b-form-group
                            label-for="numeroSoporte"
                            label="Número soporte"
                          >
                            <b-form-input
                              id="numeroSoporte"
                              ref="numeroSoporte"
                              v-model="formCampana.numeroSoporte"
                              placeholder="Ingresar numero"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="8">
                          <b-form-group
                            label-for="urlChatSoporte"
                            label="URL Chat soporte"
                          >
                            <b-form-input
                              id="urlChatSoporte"
                              ref="urlChatSoporte"
                              v-model="formCampana.urlChat"
                              placeholder="Ingresar url"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-for="urlReporteSupervisor"
                            label="URL reporte supervisor"
                          >
                            <b-form-input
                              id="urlReporteSupervisor"
                              ref="urlReporteSupervisor"
                              v-model="formCampana.urlReporteSupervisor"
                              placeholder="Ingresar url"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-for="urlReporteAdministrador"
                            label="URL reporte administrador"
                          >
                            <b-form-input
                              id="urlReporteAdministrador"
                              ref="urlReporteAdministrador"
                              v-model="formCampana.urlReporteAdministrador"
                              placeholder="Ingresar url"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="4">
                          <b-form-group
                            label-for="autenticador"
                            label="Autenticador"
                          >
                            <b-form-select
                              id="autenticador"
                              ref="autenticador"
                              v-model="formCampana.autenticacion"
                              :options="optionsAutenticacion"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group
                            label-for="formulario"
                            label="Formulario cantadas"
                          >
                            <b-form-select
                              id="formulario"
                              ref="formulario"
                              v-model="formCampana.formularioCantadas"
                              :options="optionsFormulario"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group
                            label-for="estado"
                            label="Estado *"
                          >
                            <validation-provider
                              #default="{ errors }"
                              mode="passive"
                              name="estado"
                              rules="required"
                            >
                              <b-form-select
                                id="estado"
                                ref="estado"
                                v-model="formCampana.estado"
                                :options="optionsEstado"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-tab>
                    <b-tab
                      v-if="formCampana.id > 0"
                      title="Poppup Inicio"
                    >
                      <b-row>
                        <b-col
                          cols="12"
                          md="3"
                        >
                          <b-img
                            :src="formCampana.poppup.imagen"
                            fluid-grow
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          md="9"
                        >
                          <b-row>
                            <b-col cols="12">
                              <b-form-group
                                label="Mensaje"
                              >
                                <b-form-textarea
                                  v-model="formCampana.poppup.mensaje"
                                  rows="2"
                                  max-rows="6"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12">
                              <b-form-group
                                label-for="imagenFile"
                                label="Imagen"
                              >
                                <b-form-file
                                  id="imagenFile"
                                  ref="imagenFile"
                                  v-model="formCampana.poppup.imagenFile"
                                  placeholder="Seleccionar imagen..."
                                  drop-placeholder="Eliminar imagen..."
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col
                              cols="12"
                            >
                              <b-form-group
                                label-for="inicio"
                                label="Fecha inicio"
                              >
                                <b-form-datepicker
                                  v-model="formCampana.poppup.fechaIni"
                                  selected-variant="primary"
                                  :hide-header="true"
                                  placeholder="Seleccionar fecha"
                                  locale="es"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col
                              cols="12"
                            >
                              <b-form-group
                                label-for="final"
                                label="Fecha final"
                              >
                                <b-form-datepicker
                                  v-model="formCampana.poppup.fechaFin"
                                  selected-variant="primary"
                                  :hide-header="true"
                                  placeholder="Seleccionar fecha"
                                  locale="es"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-group
                            label-for="link"
                            label="URL a cargar"
                          >
                            <b-form-input
                              id="link"
                              ref="link"
                              v-model="formCampana.poppup.url"
                              placeholder="Ingresar link"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <div class="d-flex align-items-center justify-content-between">
                            <b-button
                              v-show="$can('empresaCrear', 'empresaCrear')"
                              variant="primary"
                              @click="campanaAgregarPopup"
                            >
                              <feather-icon
                                icon="SaveIcon"
                                class="mr-50 text-white"
                              />
                              <span class="text-white">Grabar datos</span>
                            </b-button>
                            <b-form-group
                              label-for="estado"
                              label="Estado"
                            >
                              <b-form-select
                                id="estado"
                                ref="estado"
                                v-model="formCampana.poppup.estado"
                                :options="estadoPopup"
                                @input="campanaActualizarPopup"
                              />
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </b-tab>
                    <b-tab
                      v-if="formCampana.id > 0"
                      title="Productos de la campaña"
                    >
                      <b-row>
                        <b-col cols="12">
                          <b-table
                            responsive
                            hover
                            show-empty
                            bordered
                            :small="true"
                            :items="formCampana.productos"
                            :fields="fieldsCampanaProducto"
                            empty-text=" "
                            sticky-header="200px"
                          >
                            <template #empty>
                              <h4 class="text-center my-2">
                                No tiene información para mostrar
                              </h4>
                            </template>
                            <template #table-busy>
                              <div class="text-center my-2">
                                <b-spinner class="align-middle" />
                                <strong class="ml-1">Cargando ...</strong>
                              </div>
                            </template>
                            <template #head()="scope">
                              <div class="text-nowrap">
                                {{ scope.label }}
                              </div>
                            </template>
                            <template #cell()="data">
                              <span class="text-nowrap">{{ data.value }}</span>
                            </template>
                            <template #cell(acciones)="row">
                              <b-button
                                v-show="$can('empresaActualizar', 'empresaActualizar')"
                                variant="gradient-success"
                                class="btn-icon mr-50"
                                size="sm"
                                @click="campanaActualizarProducto(row.item)"
                              >
                                <feather-icon icon="EditIcon" />
                              </b-button>
                              <b-button
                                v-show="$can('empresaEliminar', 'empresaEliminar')"
                                variant="gradient-danger"
                                class="btn-icon"
                                size="sm"
                                @click="campanaEliminarProducto(row.item)"
                              >
                                <feather-icon icon="XIcon" />
                              </b-button>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="8">
                          <b-form-input
                            id="producto"
                            ref="producto"
                            v-model="formCampana.producto"
                            placeholder="Ingresar producto"
                          />
                        </b-col>
                        <b-col md="4">
                          <b-button
                            v-show="$can('empresaCrear', 'empresaCrear')"
                            variant="primary"
                            @click="campanaAgregarProducto"
                          >
                            <feather-icon
                              icon="SaveIcon"
                              class="mr-50 text-white"
                            />
                            <span class="text-white">Agregar producto</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-tab>
                    <b-tab
                      v-if="formCampana.id > 0"
                      title="Vigencia Vuina Coins"
                    >
                      <b-row>
                        <b-col
                          cols="12"
                          md="3"
                        >
                          <b-form-group
                            label-for="vigencia"
                            label="Vigencia moneda (dias)"
                          >
                            <b-form-input
                              id="vigencia"
                              ref="vigencia"
                              v-model="formCampana.vuinaCoins.vigencia"
                              type="number"
                              placeholder="Ingresar vigencia"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-tab>
                    <b-tab
                      v-if="formCampana.id > 0"
                      title="Resetear Vuina Coins"
                    >

                      <b-row>
                        <b-col
                          cols="12"
                        >
                          <div class="d-flex align-items-center justify-content-start">
                            <b-form-group
                              label-for="resetear"
                              label="Resetear todas las Vuina Coins hasta la fecha"
                            >
                              <b-form-datepicker
                                v-model="formCampana.vuinaCoins.resetear"
                                selected-variant="primary"
                                :hide-header="true"
                                placeholder="Seleccionar fecha"
                                locale="es"
                              />
                            </b-form-group>
                            <b-button
                              v-show="$can('empresaCrear', 'empresaCrear')"
                              variant="primary"
                              class="ml-2"
                              @click="campanaProceder"
                            >
                              <feather-icon
                                icon="SaveIcon"
                                class="mr-50 text-white"
                              />
                              <span class="text-white">Proceder</span>
                            </b-button>
                            <b-button
                              v-show="$can('empresaCrear', 'empresaCrear')"
                              variant="primary"
                              class="ml-2"
                              @click="campanaCancelarBorrado"
                            >
                              <feather-icon
                                icon="SaveIcon"
                                class="mr-50 text-white"
                              />
                              <span class="text-white">Cancelar borrado</span>
                            </b-button>
                          </div>
                        </b-col>
                      </b-row>
                    </b-tab>
                    <b-tab
                      v-if="formCampana.id > 0 && formCampana.mapa.length > 0 && $can('mapaCrear', 'mapaCrear')"
                      title="Mapas"
                    >
                      <b-row>
                        <b-col>
                          <b-tabs
                            v-model="tabMapa"
                          >
                            <b-tab
                              v-for="mapa in formCampana.mapa"
                              :key="mapa.id"
                              :title="obtenerMapaTextoTabs(mapa.mes)"
                            >
                              <b-row>
                                <b-col
                                  cols="12"
                                >
                                  <b-card title="Mapa de supervisores">
                                    <b-card-text>
                                      <b-row>
                                        <b-col>
                                          <b-row>
                                            <b-col
                                              cols="12"
                                            >
                                              <b-form-group
                                                label-for="mapa-mes"
                                                :label="obtenerMapaTexto(mapa.mes)"
                                              >
                                                <b-form-file
                                                  id="mapa-mes"
                                                  :v-model="mapa.mapaSupervisorImage"
                                                  placeholder="."
                                                />
                                              </b-form-group>
                                            </b-col>
                                            <b-col
                                              cols="6"
                                            >
                                              <b-form-group
                                                label-for="supervisor-alto"
                                                label="Alto"
                                              >
                                                <b-form-input
                                                  id="supervisor-alto"
                                                  ref="supervisor-alto"
                                                  v-model="mapa.mapaSupervisorImageHeight"
                                                  placeholder=""
                                                />
                                              </b-form-group>
                                            </b-col>
                                            <b-col
                                              cols="6"
                                            >
                                              <b-form-group
                                                label-for="supervisor-ancho"
                                                label="Ancho"
                                              >
                                                <b-form-input
                                                  id="supervisor-ancho"
                                                  ref="supervisor-ancho"
                                                  v-model="mapa.mapaSupervisorImageWidth"
                                                  placeholder=""
                                                />
                                              </b-form-group>
                                            </b-col>
                                            <b-col
                                              cols="6"
                                            >
                                              <b-form-group
                                                label-for="supervisor-delta-x"
                                                label="Delta X"
                                              >
                                                <b-form-input
                                                  id="supervisor-delta-x"
                                                  ref="supervisor-delta-x"
                                                  v-model="mapa.mapaSupervisorPointsPositionDeltaX"
                                                  placeholder=""
                                                />
                                              </b-form-group>
                                            </b-col>
                                            <b-col
                                              cols="6"
                                            >
                                              <b-form-group
                                                label-for="supervisor-delta-y"
                                                label="Delta Y"
                                              >
                                                <b-form-input
                                                  id="supervisor-delta-y"
                                                  ref="supervisor-delta-y"
                                                  v-model="mapa.mapaSupervisorPointsPositionDeltaY"
                                                  placeholder=""
                                                />
                                              </b-form-group>
                                            </b-col>
                                          </b-row>
                                        </b-col>
                                        <b-col>
                                          <b-row>
                                            <b-col
                                              cols="12"
                                            >
                                              <b-form-group
                                                label-for="lista-punto"
                                                label="Lista de puntos"
                                              >
                                                <b-form-textarea
                                                  id="lista-punto-enero"
                                                  v-model="mapa.puntosMapaSupervisor"
                                                  placeholder=""
                                                  rows="7"
                                                  max-rows="7"
                                                />
                                              </b-form-group>
                                            </b-col>
                                          </b-row>
                                        </b-col>
                                        <b-col
                                          cols="12"
                                          md="3"
                                          class="row align-items-center"
                                        >
                                          <b-img
                                            :src="mapa.mapaSupervisor"
                                            fluid-grow
                                            @click="showSingle('Imagen', mapa.mapaSupervisor)"
                                          />
                                        </b-col>
                                      </b-row>
                                    </b-card-text>
                                  </b-card>
                                </b-col>
                                <b-col
                                  cols="12"
                                >
                                  <b-card title="Mapa de agente">
                                    <b-card-text>
                                      <b-row>
                                        <b-col>
                                          <b-row>
                                            <b-col
                                              cols="12"
                                            >
                                              <b-form-group
                                                label-for="mapa-mes-agente"
                                                :label="obtenerMapaTexto(mapa.mes)"
                                              >
                                                <b-form-file
                                                  id="mapa-mes-agente"
                                                  :v-model="(mapa.mapaImagen) ? null : mapa.mapaImagen"
                                                  placeholder="."
                                                />
                                              </b-form-group>
                                            </b-col>
                                            <b-col
                                              cols="6"
                                            >
                                              <b-form-group
                                                label-for="supervisor-alto"
                                                label="Alto"
                                              >
                                                <b-form-input
                                                  id="supervisor-alto"
                                                  ref="supervisor-alto"
                                                  v-model="mapa.mapaAgenteImageHeight"
                                                  placeholder=""
                                                />
                                              </b-form-group>
                                            </b-col>
                                            <b-col
                                              cols="6"
                                            >
                                              <b-form-group
                                                label-for="supervisor-ancho"
                                                label="Ancho"
                                              >
                                                <b-form-input
                                                  id="supervisor-ancho"
                                                  ref="supervisor-ancho"
                                                  v-model="mapa.mapaAgenteImageWidth"
                                                  placeholder=""
                                                />
                                              </b-form-group>
                                            </b-col>
                                            <b-col
                                              cols="6"
                                            >
                                              <b-form-group
                                                label-for="supervisor-delta-x"
                                                label="Delta X"
                                              >
                                                <b-form-input
                                                  id="supervisor-delta-x"
                                                  ref="supervisor-delta-x"
                                                  v-model="mapa.mapaAgentePointsPositionDeltaX"
                                                  placeholder=""
                                                />
                                              </b-form-group>
                                            </b-col>
                                            <b-col
                                              cols="6"
                                            >
                                              <b-form-group
                                                label-for="supervisor-delta-y"
                                                label="Delta Y"
                                              >
                                                <b-form-input
                                                  id="supervisor-delta-y"
                                                  ref="supervisor-delta-y"
                                                  v-model="mapa.manaAgentePointsPositionDeltaY"
                                                  placeholder=""
                                                />
                                              </b-form-group>
                                            </b-col>
                                          </b-row>
                                        </b-col>
                                        <b-col>
                                          <b-row>
                                            <b-col
                                              cols="12"
                                            >
                                              <b-form-group
                                                label-for="lista-punto-enero"
                                                label="Lista de puntos"
                                              >
                                                <b-form-textarea
                                                  id="lista-punto-enero"
                                                  v-model="mapa.puntosMapaAgente"
                                                  placeholder=""
                                                  rows="7"
                                                  max-rows="7"
                                                />
                                              </b-form-group>
                                            </b-col>
                                          </b-row>
                                        </b-col>
                                        <b-col
                                          cols="12"
                                          md="3"
                                          class="row align-items-center"
                                        >
                                          <b-img
                                            :src="mapa.mapa"
                                            fluid-grow
                                            @click="showSingle('Imagen', mapa.mapa)"
                                          />
                                        </b-col>
                                      </b-row>
                                    </b-card-text>
                                  </b-card>
                                </b-col>
                              </b-row>
                            </b-tab>
                          </b-tabs>
                          <div class="d-flex align-items-center justify-content-end mb-2">
                            <b-button
                              variant="primary"
                              class="ml-1"
                              @click="registrarMapa()"
                            >
                              <feather-icon
                                icon="SaveIcon"
                                class="mr-50 text-white"
                              />
                              <span class="text-white">Registrar</span>
                            </b-button>
                          </div>
                        </b-col>
                      </b-row>

                    </b-tab>
                    <b-tab
                      v-if="formCampana.id > 0 && $can('feriaVer', 'feriaVer')"
                      title="Feria"
                    >
                      <b-row>
                        <b-col md="3">
                          <b-row>
                            <b-col cols="12">
                              <b-form-checkbox
                                id="checkboxDado"
                                v-model="statusDado"
                                name="checkboxDado"
                                value="1"
                                unchecked-value="0"
                                @change="activarDado"
                              >
                                Activar
                              </b-form-checkbox>
                            </b-col>
                          </b-row>
                          <b-row
                            class="mt-2"
                          >
                            <b-col
                              cols="12"
                              class="text-center"
                            >
                              <b-img
                                :src="imgDado"
                                fluid
                                width="150"
                                height="150"
                              />
                            </b-col>
                          </b-row>
                          <b-row
                            class="mt-2"
                          >
                            <b-col
                              cols="12"
                            >
                              <b-form-group
                                label-for="costo"
                                label="Costo"
                              >
                                <b-form-input
                                  v-model="dado.costo"
                                  placeholder="Ingresar costo"
                                  :disabled="(statusDado==1) ? false : true"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="9">
                          <b-row>
                            <b-col cols="8">
                              Premio
                            </b-col>
                            <b-col cols="2">
                              Stock
                            </b-col>
                            <b-col cols="2">
                              Peso
                            </b-col>
                          </b-row>
                          <b-row class="mt-1">
                            <b-col cols="8">
                              <div class="d-flex">
                                <span class="h3">1</span>
                                <b-form-input
                                  v-model="dado.premio1"
                                  class="ml-1"
                                  maxlength="25"
                                  :disabled="(statusDado==1) ? false : true"
                                />
                              </div>

                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="dado.stock1"
                                :disabled="(statusDado==1) ? false : true"
                              />
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="dado.peso1"
                                :disabled="(statusDado==1) ? false : true"
                              />
                            </b-col>
                          </b-row>
                          <b-row class="mt-1">
                            <b-col cols="8">
                              <div class="d-flex">
                                <span class="h3">2</span>
                                <b-form-input
                                  v-model="dado.premio2"
                                  class="ml-1"
                                  maxlength="25"
                                  :disabled="(statusDado==1) ? false : true"
                                />
                              </div>
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="dado.stock2"
                                :disabled="(statusDado==1) ? false : true"
                              />
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="dado.peso2"
                                :disabled="(statusDado==1) ? false : true"
                              />
                            </b-col>
                          </b-row>
                          <b-row class="mt-1">
                            <b-col cols="8">
                              <div class="d-flex">
                                <span class="h3">3</span>
                                <b-form-input
                                  v-model="dado.premio3"
                                  class="ml-1"
                                  maxlength="25"
                                  :disabled="(statusDado==1) ? false : true"
                                />
                              </div>
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="dado.stock3"
                                :disabled="(statusDado==1) ? false : true"
                              />
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="dado.peso3"
                                :disabled="(statusDado==1) ? false : true"
                              />
                            </b-col>
                          </b-row>
                          <b-row class="mt-1">
                            <b-col cols="8">
                              <div class="d-flex">
                                <span class="h3">4</span>
                                <b-form-input
                                  v-model="dado.premio4"
                                  class="ml-1"
                                  maxlength="25"
                                  :disabled="(statusDado==1) ? false : true"
                                />
                              </div>
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="dado.stock4"
                                :disabled="(statusDado==1) ? false : true"
                              />
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="dado.peso4"
                                :disabled="(statusDado==1) ? false : true"
                              />
                            </b-col>
                          </b-row>
                          <b-row class="mt-1">
                            <b-col cols="8">
                              <div class="d-flex">
                                <span class="h3">5</span>
                                <b-form-input
                                  v-model="dado.premio5"
                                  class="ml-1"
                                  maxlength="25"
                                  :disabled="(statusDado==1) ? false : true"
                                />
                              </div>
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="dado.stock5"
                                :disabled="(statusDado==1) ? false : true"
                              />
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="dado.peso5"
                                :disabled="(statusDado==1) ? false : true"
                              />
                            </b-col>
                          </b-row>
                          <b-row class="mt-1">
                            <b-col cols="8">
                              <div class="d-flex">
                                <span class="h3">6</span>
                                <b-form-input
                                  v-model="dado.premio6"
                                  class="ml-1"
                                  maxlength="25"
                                  :disabled="(statusDado==1) ? false : true"
                                />
                              </div>
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="dado.stock6"
                                :disabled="(statusDado==1) ? false : true"
                              />
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="dado.peso6"
                                :disabled="(statusDado==1) ? false : true"
                              />
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <hr>
                      <b-row class="mt-2">
                        <b-col md="3">
                          <b-row>
                            <b-col cols="12">
                              <b-form-checkbox
                                v-model="statusRuleta"
                                value="1"
                                unchecked-value="0"
                                @change="activarRuleta"
                              >
                                Activar
                              </b-form-checkbox>
                            </b-col>
                          </b-row>
                          <b-row
                            class="mt-2"
                          >
                            <b-col
                              cols="12"
                              class="text-center"
                            >
                              <b-img
                                :src="imgRuleta"
                                fluid
                                width="150"
                                height="150"
                              />
                            </b-col>
                          </b-row>
                          <b-row
                            class="mt-2"
                          >
                            <b-col
                              cols="12"
                            >
                              <b-form-group
                                label-for="costo"
                                label="Costo"
                              >
                                <b-form-input
                                  v-model="ruleta.costo"
                                  placeholder="Ingresar costo"
                                  :disabled="(statusRuleta==1) ? false : true"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="9">
                          <b-row>
                            <b-col cols="8">
                              Premio
                            </b-col>
                            <b-col cols="2">
                              Stock
                            </b-col>
                            <b-col cols="2">
                              Peso
                            </b-col>
                          </b-row>
                          <b-row class="mt-1">
                            <b-col cols="8">
                              <div class="d-flex">
                                <span class="h3">1</span>
                                <b-form-input
                                  v-model="ruleta.premio1"
                                  class="ml-1"
                                  maxlength="25"
                                  :disabled="(statusRuleta==1) ? false : true"
                                />
                              </div>
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="ruleta.stock1"
                                :disabled="(statusRuleta==1) ? false : true"
                              />
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="ruleta.peso1"
                                :disabled="(statusRuleta==1) ? false : true"
                              />
                            </b-col>
                          </b-row>
                          <b-row class="mt-1">
                            <b-col cols="8">
                              <div class="d-flex">
                                <span class="h3">2</span>
                                <b-form-input
                                  v-model="ruleta.premio2"
                                  class="ml-1"
                                  maxlength="25"
                                  :disabled="(statusRuleta==1) ? false : true"
                                />
                              </div>
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="ruleta.stock2"
                                :disabled="(statusRuleta==1) ? false : true"
                              />
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="ruleta.peso2"
                                :disabled="(statusRuleta==1) ? false : true"
                              />
                            </b-col>
                          </b-row>
                          <b-row class="mt-1">
                            <b-col cols="8">
                              <div class="d-flex">
                                <span class="h3">3</span>
                                <b-form-input
                                  v-model="ruleta.premio3"
                                  class="ml-1"
                                  maxlength="25"
                                  :disabled="(statusRuleta==1) ? false : true"
                                />
                              </div>
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="ruleta.stock3"
                                :disabled="(statusRuleta==1) ? false : true"
                              />
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="ruleta.peso3"
                                :disabled="(statusRuleta==1) ? false : true"
                              />
                            </b-col>
                          </b-row>
                          <b-row class="mt-1">
                            <b-col cols="8">
                              <div class="d-flex">
                                <span class="h3">4</span>
                                <b-form-input
                                  v-model="ruleta.premio4"
                                  class="ml-1"
                                  maxlength="25"
                                  :disabled="(statusRuleta==1) ? false : true"
                                />
                              </div>
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="ruleta.stock4"
                                :disabled="(statusRuleta==1) ? false : true"
                              />
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="ruleta.peso4"
                                :disabled="(statusRuleta==1) ? false : true"
                              />
                            </b-col>
                          </b-row>
                          <b-row class="mt-1">
                            <b-col cols="8">
                              <div class="d-flex">
                                <span class="h3">5</span>
                                <b-form-input
                                  v-model="ruleta.premio5"
                                  class="ml-1"
                                  maxlength="25"
                                  :disabled="(statusRuleta==1) ? false : true"
                                />
                              </div>
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="ruleta.stock5"
                                :disabled="(statusRuleta==1) ? false : true"
                              />
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="ruleta.peso5"
                                :disabled="(statusRuleta==1) ? false : true"
                              />
                            </b-col>
                          </b-row>
                          <b-row class="mt-1">
                            <b-col cols="8">
                              <div class="d-flex">
                                <span class="h3">6</span>
                                <b-form-input
                                  v-model="ruleta.premio6"
                                  class="ml-1"
                                  maxlength="25"
                                  :disabled="(statusRuleta==1) ? false : true"
                                />
                              </div>
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="ruleta.stock6"
                                :disabled="(statusRuleta==1) ? false : true"
                              />
                            </b-col>
                            <b-col cols="2">
                              <b-form-input
                                v-model="ruleta.peso6"
                                :disabled="(statusRuleta==1) ? false : true"
                              />
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>

                      <div class="d-flex align-items-center justify-content-end my-2">
                        <b-button
                          variant="primary"
                          class="ml-1"
                          @click="registrarFeria()"
                        >
                          <feather-icon
                            icon="SaveIcon"
                            class="mr-50 text-white"
                          />
                          <span class="text-white">Grabar</span>
                        </b-button>
                        <b-button
                          variant="primary"
                          class="ml-1"
                          @click="actualizarFeria()"
                        >
                          <feather-icon
                            icon="SaveIcon"
                            class="mr-50 text-white"
                          />
                          <span class="text-white">Nueva Feria</span>
                        </b-button>
                      </div>
                    </b-tab>
                  </b-tabs>
                </validation-observer>
              </b-col>
            </b-row>
            <b-row v-if="tabIndexCampana==0 || tabIndexCampana==3">
              <b-col>
                <div class="d-flex align-items-center justify-content-end mb-2">
                  <b-button
                    v-show="$can('empresaCrear', 'empresaCrear')"
                    variant="primary"
                    class="ml-1"
                    @click="registrarCampana()"
                  >
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50 text-white"
                    />
                    <span class="text-white">Registrar</span>
                  </b-button>
                  <b-button
                    v-if="tabIndexCampana==0"
                    variant="danger"
                    class="ml-1"
                    @click="limpiarCampana()"
                  >
                    <feather-icon
                      icon="FileIcon"
                      class="mr-50"
                    />
                    Limpiar
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

    </b-modal>
    <b-modal
      v-model="popupMetrica"
      hide-footer
      scrollable
      centered
      no-close-on-backdrop
      size="lg"
      title="Detalle métrica"
    >
      <b-row>
        <b-col>
          <div class="d-flex align-items-center justify-content-end mb-2">
            <b-button
              v-show="$can('empresaCrear', 'empresaCrear')"
              variant="primary"
              class="ml-1"
              @click="modalMetrica(0)"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50 text-white"
              />
              <span class="text-white">Agregar métrica</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-table
        responsive
        striped
        hover
        show-empty
        bordered
        sticky-header
        :small="true"
        :busy="cargandoMetrica"
        :items="itemsMetrica"
        :fields="fieldsMetrica"
        :filter="filterMetrica"
        :filter-included-fields="filterOnMetrica"
        empty-filtered-text="No se encontro ningun resultado"
        empty-text=" "
      >
        <template #empty>
          <h4 class="text-center my-2">
            No tiene información para mostrar
          </h4>
        </template>
        <template #emptyfiltered="scope">
          <h4 class="text-center my-2">
            {{ scope.emptyFilteredText }}
          </h4>
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
            <strong class="ml-1">Cargando ...</strong>
          </div>
        </template>
        <template #head()="scope">
          <div class="text-nowrap">
            {{ scope.label }}
          </div>
        </template>
        <template #cell()="data">
          <span class="text-nowrap">{{ data.value }}</span>
        </template>
        <template #cell(acciones)="row">
          <b-button
            v-show="$can('empresaActualizar', 'empresaActualizar')"
            variant="gradient-success"
            class="btn-icon mr-50"
            size="sm"
            @click="modalMetrica(row.item.id)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            v-show="$can('empresaEliminar', 'empresaEliminar')"
            variant="gradient-danger"
            class="btn-icon"
            size="sm"
            @click="eliminarMetrica(row.item.id)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </template>
      </b-table>
    </b-modal>
    <b-modal
      v-model="popupAgregarMetrica"
      hide-footer
      scrollable
      no-close-on-backdrop
      size="lg"
      :title="tituloMetrica"
      @hidde="limpiarMetrica()"
    >
      <b-row>
        <b-col>
          <div class="d-flex align-items-center justify-content-end mb-2">
            <b-button
              v-show="$can('empresaCrear', 'empresaCrear')"
              variant="primary"
              class="ml-1"
              @click="registrarMetrica()"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50 text-white"
              />
              <span class="text-white">Registrar</span>
            </b-button>
            <b-button
              variant="danger"
              class="ml-1"
              @click="limpiarMetrica()"
            >
              <feather-icon
                icon="FileIcon"
                class="mr-50"
              />
              Limpiar
            </b-button>
          </div>
        </b-col>
      </b-row>
      <p class="text-right">
        <small class="text-muted">Los campos marcados con * son obligatorios</small>
      </p>
      <validation-observer ref="filterMetrica">
        <b-row>
          <b-col>
            <b-form-group
              label-for="nombre"
              label="Nombre *"
            >
              <validation-provider
                #default="{ errors }"
                mode="passive"
                name="nombre"
                rules="required"
              >
                <b-form-input
                  id="nombre"
                  ref="nombre"
                  v-model="formMetrica.nombre"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Ingresar nombre"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              label-for="indicador"
              label="Indicador *"
            >
              <validation-provider
                #default="{ errors }"
                mode="passive"
                name="indicador"
                rules="required"
              >
                <b-form-input
                  id="indicador"
                  ref="indicador"
                  v-model="formMetrica.indicador"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Ingresar indicador"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label-for="objetivo"
              label="Objetivo *"
            >
              <validation-provider
                #default="{ errors }"
                mode="passive"
                name="objetivo"
                rules="required"
              >
                <b-form-input
                  id="objetivo"
                  ref="objetivo"
                  v-model="formMetrica.objetivo"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Ingresar objetivo"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              label-for="cumplimiento"
              label="Cumplimiento *"
            >
              <validation-provider
                #default="{ errors }"
                mode="passive"
                name="cumplimiento"
                rules="required"
              >
                <b-form-select
                  id="cumplimiento"
                  ref="cumplimiento"
                  v-model="formMetrica.cumplimiento"
                  :options="optionsCumplimiento"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              label-for="tipo"
              label="Tipo *"
            >
              <validation-provider
                #default="{ errors }"
                mode="passive"
                name="tipo"
                rules="required"
              >
                <b-form-select
                  id="tipo"
                  ref="tipo"
                  v-model="formMetrica.tipo"
                  :options="optionsTipo"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              label-for="estado"
              label="Estado *"
            >
              <validation-provider
                #default="{ errors }"
                mode="passive"
                name="estado"
                rules="required"
              >
                <b-form-select
                  id="estado"
                  ref="estado"
                  v-model="formMetrica.status"
                  :options="optionsEstado"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
    <b-modal
      v-model="popupUsuario"
      hide-footer
      scrollable
      no-close-on-backdrop
      size="lg"
      title="Detalle usuario"
      @hidden="idArea=0"
    >
      <div class="d-flex justify-content-between flex-wrap my-2">
        <!-- filter -->
        <b-form-group
          label="Buscador"
          label-cols-sm="3"
          label-align-sm="left"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="buscadorUsuario"
              placeholder="Buscador"
              type="search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!buscadorUsuario"
                @click="buscadorUsuario = ''"
              >
                Limpiar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <b-row>
        <b-col>
          <div class="d-flex align-items-center justify-content-end mb-2">
            <b-button
              v-show="$can('empresaCrear', 'empresaCrear')"
              variant="primary"
              class="ml-1"
              @click="modalUsuario(0)"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50 text-white"
              />
              <span class="text-white">Agregar usuario</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-table
        responsive
        striped
        hover
        show-empty
        bordered
        sticky-header
        :small="true"
        :items="itemsUsuario"
        :fields="fieldsUsuario"
        :filter="buscadorUsuario"
        :filter-included-fields="filterOnUsuario"
      >
        <template #empty>
          <h3 class="text-center my-2">
            No tiene información para mostrar
          </h3>
        </template>
        <template #emptyfiltered>
          <h3 class="text-center my-2">
            No se encontro ningun resultado
          </h3>
        </template>
        <template #head()="scope">
          <div class="text-nowrap">
            {{ scope.label }}
          </div>
        </template>
        <template #cell()="data">
          <span class="text-nowrap">{{ data.value }}</span>
        </template>
        <template #cell(acciones)="row">
          <b-button
            v-show="$can('empresaActualizar', 'empresaActualizar')"
            :id="`modalUsuario${row.item.id}`"
            variant="gradient-success"
            class="btn-icon mr-50"
            size="sm"
            @click="modalUsuario(row.item.id)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            v-show="$can('empresaEliminar', 'empresaEliminar')"
            :id="`eliminarUsuario${row.item.id}`"
            variant="gradient-danger"
            class="btn-icon"
            size="sm"
            @click="eliminarUsuario(row.item.id)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
          <b-tooltip
            :target="`modalUsuario${row.item.id}`"
            title="Editar usuario"
          />
          <b-tooltip
            :target="`eliminarUsuario${row.item.id}`"
            title="Eliminar usuario"
          />
        </template>
        <template #cell(nombre)="row">
          {{ row.item.nombre }} {{ row.item.apellidoPaterno }} {{ row.item.apellidoMaterno }}
        </template>
      </b-table>
    </b-modal>
    <b-modal
      v-model="popupAgregarUsuario"
      hide-footer
      scrollable
      no-close-on-backdrop
      :size="'xl'"
      :title="tituloUsuario"
      @hidde="limpiarUsuario()"
    >
      <b-row>
        <b-col>
          <div class="d-flex align-items-center justify-content-end mb-2">
            <b-button
              v-if="formUsuario.id>0"
              variant="primary"
              class="ml-1"
              @click="verVuina()"
            >
              <feather-icon
                icon="InfoIcon"
                class="mr-50 text-white"
              />
              <span class="text-white">Ver en vuina</span>
            </b-button>
            <b-button
              v-if="formUsuario.id>0"
              variant="primary"
              class="ml-1"
              @click="verQR()"
            >
              <feather-icon
                icon="InfoIcon"
                class="mr-50 text-white"
              />
              <span class="text-white">Ver QR</span>
            </b-button>
            <b-button
              v-if="formUsuario.id>0"
              variant="primary"
              class="ml-1"
              @click="reenviarQR()"
            >
              <feather-icon
                icon="SendIcon"
                class="mr-50 text-white"
              />
              <span class="text-white">Reenviar QR</span>
            </b-button>
            <b-button
              v-if="formUsuario.id > 0"
              variant="primary"
              class="ml-1"
              @click="modalClaveUsuario(formUsuario.id)"
            >
              <feather-icon
                icon="StopCircleIcon"
                class="mr-50 text-white"
              />
              <span class="text-white">Resetear contraseña</span>
            </b-button>
            <b-button
              variant="primary"
              class="ml-1"
              @click="registrarUsuario()"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50 text-white"
              />
              <span class="text-white">Registrar</span>
            </b-button>
            <b-button
              variant="danger"
              class="ml-1"
              @click="limpiarUsuario()"
            >
              <feather-icon
                icon="FileIcon"
                class="mr-50"
              />
              Limpiar
            </b-button>
          </div>
        </b-col>
      </b-row>
      <p class="text-right">
        <small class="text-muted">Los campos marcados con * son obligatorios</small>
      </p>
      <b-row>
        <b-col
          v-if="formUsuario.avatar != null"
          cols="12"
          md="3"
          class="text-center"
        >
          <b-row>
            <b-col cols="12">
              <span
                v-html="formUsuario.avatar.svg"
              />
            </b-col>
            <b-col
              cols="12"
            >
              <div class="d-flex align-items-center justify-content-between mt-2">
                <b-button
                  variant="primary"
                  class="ml-1"
                  @click="verQR()"
                >
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50 text-white"
                  />
                  <span class="text-white">Ver QR</span>
                </b-button>
                <b-button
                  variant="primary"
                  class="ml-1"
                  @click="reenviarQR()"
                >
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50 text-white"
                  />
                  <span class="text-white">Reenviar QR</span>
                </b-button>
              </div>

            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="formUsuario.avatar != null"
          cols="12"
          md="9"
        >
          <validation-observer ref="filterUsuario">
            <b-row>
              <b-col>
                <b-form-group
                  label-for="nickname"
                  label="Nickname *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="nickname"
                    rules="required"
                  >
                    <b-form-input
                      id="nickname"
                      ref="nickname"
                      v-model="formUsuario.nickname"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar nickname"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="perfil"
                  label="Perfil *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="apellido materno"
                    rules="required"
                  >
                    <b-form-select
                      id="perfil"
                      ref="perfil"
                      v-model="formUsuario.perfil"
                      :options="optionsPerfil"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="rol"
                  label="Rol *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="rol"
                    rules="required"
                  >
                    <b-form-select
                      id="rol"
                      ref="rol"
                      v-model="formUsuario.rol"
                      :options="optionsRol"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group
                  label-for="tipo"
                  label="Tipo documento *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="tipo documento"
                    rules="required"
                  >
                    <b-form-select
                      id="tipo"
                      ref="tipo"
                      v-model="formUsuario.tipo"
                      :options="optionsTipoDocumento"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label-for="numero"
                  label="Número DNI *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="número DNI"
                    rules="required"
                  >
                    <b-form-input
                      id="numero"
                      ref="numero"
                      v-model="formUsuario.numero"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar número de DNI"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="nombre"
                  label="Nombre *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="nombre"
                    rules="required"
                  >
                    <b-form-input
                      id="nombre"
                      ref="nombre"
                      v-model="formUsuario.nombre"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar nombre"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="apellido-paterno"
                  label="Apellido paterno *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="apellido paterno"
                    rules="required"
                  >
                    <b-form-input
                      id="apellido-paterno"
                      ref="apellidoPeterno"
                      v-model="formUsuario.apellidoPaterno"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar apellido"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="apellido-materno"
                  label="Apellido materno *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="apellido materno"
                    rules="required"
                  >
                    <b-form-input
                      id="apellido-materno"
                      ref="apellidoMaterno"
                      v-model="formUsuario.apellidoMaterno"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar apellido"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group
                  label-for="cumpleanos"
                  label="Cumpleaños (dd/mm/aaaa)"
                >
                  <b-form-datepicker
                    id="cumpleanos"
                    ref="cumpleanos"
                    v-model="formUsuario.cumpleanos"
                    placeholder="Ingresar fecha"
                    locale="es"
                    :date-format-options="{ year: 'numeric' }"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="email"
                  label="Email"
                >
                  <b-form-input
                    id="email"
                    ref="email"
                    v-model="formUsuario.email"
                    placeholder="Ingresar email"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="campana"
                  label="Campaña *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="estado"
                    rules="required"
                  >
                    <b-form-select
                      id="camapna"
                      ref="campana"
                      v-model="formUsuario.campana"
                      :options="optionsCampana"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="supervisor"
                  label="Supervisor *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="supervisor"
                    rules="required"
                  >
                    <b-form-input
                      id="supervisor"
                      ref="supervisor"
                      v-model="formUsuario.supervisor"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar número de DNI"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="estado"
                  label="Estado *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="estado"
                    rules="required"
                  >
                    <b-form-select
                      id="estado"
                      ref="estado"
                      v-model="formUsuario.estado"
                      :options="optionsEstado"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-col>
        <b-col
          v-if="formUsuario.avatar == null"
          cols="12"
        >
          <validation-observer ref="filterUsuario">
            <b-row>
              <b-col>
                <b-form-group
                  label-for="nickname"
                  label="Nickname *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="nickname"
                    rules="required"
                  >
                    <b-form-input
                      id="nickname"
                      ref="nickname"
                      v-model="formUsuario.nickname"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar nickname"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="perfil"
                  label="Perfil *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="apellido materno"
                    rules="required"
                  >
                    <b-form-select
                      id="perfil"
                      ref="perfil"
                      v-model="formUsuario.perfil"
                      :options="optionsPerfil"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="rol"
                  label="Rol *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="rol"
                    rules="required"
                  >
                    <b-form-select
                      id="rol"
                      ref="rol"
                      v-model="formUsuario.rol"
                      :options="optionsRol"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group
                  label-for="tipo"
                  label="Tipo documento *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="tipo documento"
                    rules="required"
                  >
                    <b-form-select
                      id="tipo"
                      ref="tipo"
                      v-model="formUsuario.tipo"
                      :options="optionsTipoDocumento"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label-for="numero"
                  label="Número documento *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="número documento"
                    rules="required"
                  >
                    <b-form-input
                      id="numero"
                      ref="numero"
                      v-model="formUsuario.numero"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar número"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="nombre"
                  label="Nombre *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="nombre"
                    rules="required"
                  >
                    <b-form-input
                      id="nombre"
                      ref="nombre"
                      v-model="formUsuario.nombre"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar nombre"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="apellido-paterno"
                  label="Apellido paterno *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="apellido paterno"
                    rules="required"
                  >
                    <b-form-input
                      id="apellido-paterno"
                      ref="apellidoPeterno"
                      v-model="formUsuario.apellidoPaterno"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar apellido"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="apellido-materno"
                  label="Apellido materno *"
                >
                  <b-form-input
                    id="apellido-materno"
                    ref="apellidoMaterno"
                    v-model="formUsuario.apellidoMaterno"
                    placeholder="Ingresar apellido"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group
                  label-for="cumpleanos"
                  label="Cumpleaños (dd/mm/aaaa)*"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="cumpleaños"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="cumpleanos"
                      ref="cumpleanos"
                      v-model="formUsuario.cumpleanos"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar fecha"
                      locale="es"
                      :date-format-options="{ year: 'numeric' }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="email"
                  label="Email *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      ref="email"
                      v-model="formUsuario.email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="campana"
                  label="Campaña *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="estado"
                    rules="required"
                  >
                    <b-form-select
                      id="camapna"
                      ref="campana"
                      v-model="formUsuario.campana"
                      :options="optionsCampana"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="supervisor"
                  label="Supervisor *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="supervisor"
                    rules="required"
                  >
                    <b-form-input
                      id="supervisor"
                      ref="supervisor"
                      v-model="formUsuario.supervisor"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar supervisor"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="estado"
                  label="Estado *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="estado"
                    rules="required"
                  >
                    <b-form-select
                      id="estado"
                      ref="estado"
                      v-model="formUsuario.estado"
                      :options="optionsEstado"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-col>
      </b-row>

    </b-modal>
    <vue-easy-lightbox
      esc-disabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BTabs,
  BTab,
  BCardTitle,
  BTable,
  BButton,
  BModal,
  BFormSelect,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BTooltip,
  BFormCheckbox,
  BImg,
  BFormTextarea,
  BFormFile,
  BCardText,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueEasyLightbox from 'vue-easy-lightbox'

import { required, email } from '@validations'
// eslint-disable-next-line import/no-cycle
import empresaService from '@/service/empresaService'
// eslint-disable-next-line import/no-cycle
import perfilService from '@/service/perfilService'
// eslint-disable-next-line import/no-cycle
import areaService from '@/service/areaService'
// eslint-disable-next-line import/no-cycle
import campanaService from '@/service/campanaService'
// eslint-disable-next-line import/no-cycle
import metricaService from '@/service/metricaService'
// eslint-disable-next-line import/no-cycle
import usuarioService from '@/service/usuarioService'
// eslint-disable-next-line import/no-cycle
import rolService from '@/service/rolService'
// eslint-disable-next-line import/no-cycle
import productoService from '@/service/productoService'
// eslint-disable-next-line import/no-cycle
import mapaService from '@/service/mapaService'
// eslint-disable-next-line import/no-cycle
import feriaService from '@/service/feriaService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BTabs,
    BTab,
    BTable,
    BButton,
    BModal,
    BFormSelect,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BTooltip,
    BFormCheckbox,
    BImg,
    BFormTextarea,
    BFormFile,
    BCardTitle,
    BCardText,
    VueEasyLightbox,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      token: localStorage.getItem('token'),
      idEmpresa: 0,
      required,
      email,
      activeTab: 0,
      tabIndex: 0,
      tabIndexCampana: 0,
      filter: null,
      filterOn: [],
      fieldsEmpresa: [
        {
          key: 'id',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          label: 'RUC',
          key: 'ruc',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Empresa',
          key: 'name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Pais',
          key: 'pais',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Contacto',
          key: 'personacontacto',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Teléfono',
          key: 'telfcontacto',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Selección',
          key: 'acciones',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      itemsEmpresa: [],
      fieldsNoAdministrador: [
        {
          key: 'id',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          label: 'Usuario',
          key: 'username',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Nombre',
          key: 'nombreCompleto',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Empresa',
          key: 'empresa',
          sortable: false,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          label: 'Area',
          key: 'area',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Campaña',
          key: 'campana',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: ' ',
          key: 'acciones',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      itemsNoAdministrador: [],
      filterNoAdministrador: null,
      filterOnNoAdministrador: [],
      fieldsAdministrador: [
        {
          key: 'id',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          label: 'Usuario',
          key: 'username',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Nombre',
          key: 'nombreCompleto',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Empresa',
          key: 'empresa',
          sortable: false,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          label: 'Area',
          key: 'area',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Campaña',
          key: 'campana',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: '',
          key: 'acciones',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      itemsAdministrador: [],
      filterAdministrador: null,
      filterOnAdministrador: [],
      cargandoArea: false,
      filterArea: null,
      filterOnArea: [],
      fieldsArea: [
        {
          key: 'id',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          label: 'Area',
          key: 'name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Campaña',
          key: 'camp_empr',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Usuario',
          key: 'usua_empr',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Incentivos',
          key: 'ince_empr',
          sortable: false,
          thClass: 'd-none text-center',
          tdClass: 'd-none text-center',
        },
        {
          label: 'Acciones',
          key: 'acciones',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      itemsArea: [],
      cargandoCampana: false,
      filterCampana: null,
      filterOnCampana: [],
      fieldsCampana: [
        {
          key: 'id',
          thClass: 'd-none',
          tdClass: 'd-none',
        }, {
          label: 'Campaña',
          key: 'name',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        }, {
          label: 'Reinicio',
          key: 'reinicio',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        }, {
          label: 'Métricas',
          key: 'metr_camp',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        }, {
          label: 'Acciones',
          key: 'acciones',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      itemsCampana: [],
      fieldsCampanaProducto: [
        {
          key: 'id',
          thClass: 'd-none',
          tdClass: 'd-none',
        }, {
          label: 'Producto',
          key: 'producto',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        }, {
          label: 'Acciones',
          key: 'acciones',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      cargandoMetrica: false,
      filterMetrica: null,
      filterOnMetrica: [],
      fieldsMetrica: [
        {
          key: 'id',
          thClass: 'd-none',
          tdClass: 'd-none',
        }, {
          label: 'Métrica',
          key: 'name',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        }, {
          label: 'Tipo métricas',
          key: 'dataType',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        }, {
          label: 'Objetivo',
          key: 'targetValue',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        }, {
          label: 'Cumplimiento',
          key: 'compliance',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        }, {
          label: 'Acciones',
          key: 'acciones',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      itemsMetrica: [],
      optionsRol: [
        { value: null, text: 'Seleccionar rol' },
      ],
      cargandoUsuario: false,
      buscadorUsuario: null,
      filterOnUsuario: ['username', 'nombre', 'apellidoPaterno', 'apellidoMaterno', 'areaname', 'campaignname'],
      fieldsUsuario: [
        { key: 'id', thClass: 'd-none', tdClass: 'd-none' },
        {
          label: 'Usuario',
          key: 'username',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Nombre',
          key: 'nombre',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Area',
          key: 'areaname',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Campaña',
          key: 'campaignname',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Acciones',
          key: 'acciones',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      itemsUsuario: [],
      optionsPerfil: [
        { value: null, text: 'Seleccionar perfil' },
      ],
      optionsEstado: [
        { value: null, text: 'Seleccionar estado' },
        { value: '1', text: 'Activo' },
        { value: '0', text: 'Inactivo' },
      ],
      optionsTipoDocumento: [
        { value: null, text: 'Seleccionar tipo' },
        { value: 'DNI', text: 'DNI' },
        { value: 'PASAPORTE', text: 'PASAPORTE' },
        { value: 'CARNET DE EXTRANGERIA', text: 'CARNET DE EXTRANGERIA' },
      ],
      optionsCampana: [
        { value: null, text: 'Seleccionar campaña' },
      ],
      optionsReinicio: [
        { value: null, text: 'Seleccionar reinicio' },
        { value: 'anual', text: 'Anual' },
        { value: 'mensual', text: 'Mensual' },
        { value: 'semanal', text: 'Semanal' },
        { value: 'diario', text: 'Diario' },
      ],
      optionsMostrar: [
        { value: null, text: 'Seleccionar mostrar' },
      ],
      optionsAutenticacion: [
        { value: null, text: 'Seleccionar autenticacion' },
      ],
      optionsFormulario: [
        { value: null, text: 'Seleccionar formulario' },
      ],
      optionsTipo: [
        { value: null, text: 'Seleccionar tipo' },
        { value: 'PERCENTAGE', text: 'Percentage' },
        { value: 'NUMBER', text: 'Number' },
      ],
      optionsCumplimiento: [
        { value: null, text: 'Seleccionar cumplimiento' },
        { value: 'ascendente', text: 'Ascendente' },
        { value: 'descendente', text: 'Descendente' },
      ],
      estadoPopup: [
        { value: null, text: 'Seleccionar estado' },
        { value: '1', text: 'Activo' },
        { value: '0', text: 'Inactivo' },
      ],
      popupAgregarArea: false,
      tituloArea: '',
      popupCampana: false,
      popupAgregarCampana: false,
      tituloCampana: '',
      popupMetrica: false,
      popupAgregarMetrica: false,
      tituloMetrica: '',
      popupUsuario: false,
      popupAgregarUsuario: false,
      tituloUsuario: '',
      empresa: {
        nombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        area: '',
        tipoDocumento: '',
        numeroDocumento: '',
        telefono: '',
        email: '',
        licenciaUso: '',
        cantidadMaxima: '',
      },
      formArea: {
        id: 0,
        nombre: '',
        estado: null,
        empresa: '',
      },
      formUsuario: {
        id: 0,
        tipo: null,
        numero: '',
        nombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        usuario: '',
        nickname: '',
        perfil: null,
        cumpleanos: '',
        email: '',
        estado: null,
        campana: null,
        supervisor: '',
        clave: '',
        rol: null,
      },
      formCampana: {
        id: 0,
        reinicio: null,
        nombre: '',
        estado: null,
        area: '',
        mostrar: null,
        totalProducto: '',
        chatSoporte: false,
        trivia: false,
        objetivoDiario: false,
        avanceComisiones: false,
        numeroSoporte: '',
        urlChat: '',
        urlReporteSupervisor: '',
        urlReporteAdministrador: '',
        autenticacion: null,
        formularioCantadas: null,
        poppup: {
          id: 0,
          imagen: '',
          imagenFile: null,
          mensaje: '',
          fechaIni: '',
          fechaFin: '',
          url: '',
          estado: '',
        },
        producto: '',
        estadoProducto: 0,
        productos: [],
        vuinaCoins: {
          vigencia: 0,
          resetear: null,
        },
        mapa: [],
      },
      formMetrica: {
        id: 0,
        nombre: '',
        campana: '',
        indicador: '',
        objetivo: '',
        tipo: null,
        cumplimiento: '',
        status: null,
        punto: '',
        multiplicador: '',
        inferior: '',
        superior: '',
        control: '',
      },
      idArea: 0,
      idCampana: 0,
      imgs: '',
      visible: false,
      index: 0,
      tabMapa: 0,
      statusDado: 0,
      statusRuleta: 0,
      dado: {
        costo: '',
        premio1: '',
        premio2: '',
        premio3: '',
        premio4: '',
        premio5: '',
        premio6: '',
        stock1: '',
        stock2: '',
        stock3: '',
        stock4: '',
        stock5: '',
        stock6: '',
        peso1: '',
        peso2: '',
        peso3: '',
        peso4: '',
        peso5: '',
        peso6: '',
      },
      ruleta: {
        costo: '',
        premio1: '',
        premio2: '',
        premio3: '',
        premio4: '',
        premio5: '',
        premio6: '',
        stock1: '',
        stock2: '',
        stock3: '',
        stock4: '',
        stock5: '',
        stock6: '',
        peso1: '',
        peso2: '',
        peso3: '',
        peso4: '',
        peso5: '',
        peso6: '',
      },
    }
  },
  computed: {
    imgDado() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/dado.png')
    },
    imgRuleta() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/ruleta.png')
    },
  },
  mounted() {
    this.$nextTick(async () => {
      // await this.obtenerArea()
      await this.obtenerEmpresa()
      await this.obtenerRol()
      await this.obtenerMostrar()
      await this.obtenerAutenticacion()
      await this.obtenerFormularioCantadas()
    })
  },
  methods: {
    activarRuleta() {
      feriaService.postRuletaUpdate(this.token, {
        id: this.formCampana.id,
        estado: this.statusRuleta,
      })
    },
    activarDado() {
      feriaService.postDadoUpdate(this.token, {
        id: this.formCampana.id,
        estado: this.statusDado,
      })
    },
    obtenerMapaTexto(mes) {
      switch (mes) {
        case '1':
          return 'Mapa enero'
        case '2':
          return 'Mapa febrero'
        case '3':
          return 'Mapa marzo'
        case '4':
          return 'Mapa abril'
        case '5':
          return 'Mapa mayo'
        case '6':
          return 'Mapa junio'
        case '7':
          return 'Mapa julio'
        case '8':
          return 'Mapa agosto'
        case '9':
          return 'Mapa septiembre'
        case '10':
          return 'Mapa octubre'
        case '11':
          return 'Mapa noviembre'
        case '12':
          return 'diciembre'
        default:
          return ''
      }
    },
    obtenerMapaTextoTabs(mes) {
      switch (mes) {
        case '1':
          return 'Ene'
        case '2':
          return 'Febr'
        case '3':
          return 'Mar'
        case '4':
          return 'Abr'
        case '5':
          return 'May'
        case '6':
          return 'Jun'
        case '7':
          return 'Jul'
        case '8':
          return 'Ago'
        case '9':
          return 'Sep'
        case '10':
          return 'Oct'
        case '11':
          return 'Nov'
        case '12':
          return 'Dic'
        default:
          return ''
      }
    },
    async registrarFeria() {
      const data = new FormData()
      data.append('campana', this.formCampana.id)
      data.append('dadoUno', this.dado.premio1)
      data.append('dadoDos', this.dado.premio2)
      data.append('dadoTres', this.dado.premio3)
      data.append('dadoCuatro', this.dado.premio4)
      data.append('dadoCinco', this.dado.premio5)
      data.append('dadoSeis', this.dado.premio6)
      data.append('dadoPesoUno', this.dado.peso1)
      data.append('dadoPesoDos', this.dado.peso2)
      data.append('dadoPesoTres', this.dado.peso3)
      data.append('dadoPesoCuatro', this.dado.peso4)
      data.append('dadoPesoCinco', this.dado.peso5)
      data.append('dadoPesoSeis', this.dado.peso6)
      data.append('dadoStockUno', this.dado.stock1)
      data.append('dadoStockDos', this.dado.stock2)
      data.append('dadoStockTres', this.dado.stock3)
      data.append('dadoStockCuatro', this.dado.stock4)
      data.append('dadoStockCinco', this.dado.stock5)
      data.append('dadoStockSeis', this.dado.stock6)
      data.append('dadoCosto', this.dado.costo)
      data.append('ruletaUno', this.ruleta.premio1)
      data.append('ruletaDos', this.ruleta.premio2)
      data.append('ruletaTres', this.ruleta.premio3)
      data.append('ruletaCuatro', this.ruleta.premio4)
      data.append('ruletaCinco', this.ruleta.premio5)
      data.append('ruletaSeis', this.ruleta.premio6)
      data.append('ruletaPesoUno', this.ruleta.peso1)
      data.append('ruletaPesoDos', this.ruleta.peso2)
      data.append('ruletaPesoTres', this.ruleta.peso3)
      data.append('ruletaPesoCuatro', this.ruleta.peso4)
      data.append('ruletaPesoCinco', this.ruleta.peso5)
      data.append('ruletaPesoSeis', this.ruleta.peso6)
      data.append('ruletaStockUno', this.ruleta.stock1)
      data.append('ruletaStockDos', this.ruleta.stock2)
      data.append('ruletaStockTres', this.ruleta.stock3)
      data.append('ruletaStockCuatro', this.ruleta.stock4)
      data.append('ruletaStockCinco', this.ruleta.stock5)
      data.append('ruletaStockSeis', this.ruleta.stock6)
      data.append('ruletaCosto', this.ruleta.costo)

      await feriaService.postFeriaRegistrar(this.token, data)
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notificación',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Feria registrada con éxito.',
          },
        },
        {
          position: 'bottom-center',
          closeOnClick: true,
        },
      )
    },
    async actualizarFeria() {
      const { data } = await feriaService.postFeriaActualizar(this.token, this.formCampana.id)
      this.dado.premio1 = data.dadoUno
      this.dado.premio2 = data.dadoDos
      this.dado.premio3 = data.dadoTres
      this.dado.premio4 = data.dadoCuatro
      this.dado.premio5 = data.dadoCinco
      this.dado.premio6 = data.dadoSeis
      this.dado.peso1 = data.dadoPesoUno
      this.dado.peso2 = data.dadoPesoDos
      this.dado.peso3 = data.dadoPesoTres
      this.dado.peso4 = data.dadoPesoCuatro
      this.dado.peso5 = data.dadoPesoCinco
      this.dado.peso6 = data.dadoPesoSeis
      this.dado.stock1 = data.dadoStockUno
      this.dado.stock2 = data.dadoStockDos
      this.dado.stock3 = data.dadoStockTres
      this.dado.stock4 = data.dadoStockCuatro
      this.dado.stock5 = data.dadoStockCinco
      this.dado.stock6 = data.dadoStockSeis
      this.dado.costo = data.dadoCosto
      this.ruleta.premio1 = data.ruletaUno
      this.ruleta.premio2 = data.ruletaDos
      this.ruleta.premio3 = data.ruletaTres
      this.ruleta.premio4 = data.ruletaCuatro
      this.ruleta.premio5 = data.ruletaCinco
      this.ruleta.premio6 = data.ruletaSeis
      this.ruleta.peso1 = data.ruletaPesoUno
      this.ruleta.peso2 = data.ruletaPesoDos
      this.ruleta.peso3 = data.ruletaPesoTres
      this.ruleta.peso4 = data.ruletaPesoCuatro
      this.ruleta.peso5 = data.ruletaPesoCinco
      this.ruleta.peso6 = data.ruletaPesoSeis
      this.ruleta.stock1 = data.ruletaStockUno
      this.ruleta.stock2 = data.ruletaStockDos
      this.ruleta.stock3 = data.ruletaStockTres
      this.ruleta.stock4 = data.ruletaStockCuatro
      this.ruleta.stock5 = data.ruletaStockCinco
      this.ruleta.stock6 = data.ruletaStockSeis
      this.ruleta.costo = data.ruletaCosto

      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notificación',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Proceso ejecutado con éxito.',
          },
        },
        {
          position: 'bottom-center',
          closeOnClick: true,
        },
      )
    },
    async registrarMapa() {
      const data = new FormData()
      data.append('campana', this.formCampana.mapa[this.tabMapa].campaign)
      data.append('mes', this.formCampana.mapa[this.tabMapa].mes)
      data.append('imagenMapaAgente', (this.formCampana.mapa[this.tabMapa].mapaImagen === null) ? '' : this.formCampana.mapa[this.tabMapa].mapaImagen.files[0])
      data.append('puntosMapaAgente', this.formCampana.mapa[this.tabMapa].puntosMapaAgente)
      data.append('deltaXAgente', this.formCampana.mapa[this.tabMapa].mapaAgentePointsPositionDeltaX)
      data.append('deltaYAgente', this.formCampana.mapa[this.tabMapa].manaAgentePointsPositionDeltaY)
      data.append('imagenMapaSupervisor', (this.formCampana.mapa[this.tabMapa].mapaSupervisorImage === null) ? '' : this.formCampana.mapa[this.tabMapa].mapaSupervisorImage.files[0])
      data.append('puntosMapaSupervisor', this.formCampana.mapa[this.tabMapa].puntosMapaSupervisor)
      data.append('deltaXSupervisor', this.formCampana.mapa[this.tabMapa].mapaSupervisorPointsPositionDeltaX)
      data.append('deltaYSupervisor', this.formCampana.mapa[this.tabMapa].mapaSupervisorPointsPositionDeltaY)

      await mapaService.postMapaRegistrar(this.token, data)
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notificación',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Mapa registrado con éxito.',
          },
        },
        {
          position: 'bottom-center',
          closeOnClick: true,
        },
      )
    },
    showSingle(title, src) {
      this.imgs = [
        { title, src },
      ]
      this.show()
    },
    show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    async obtenerMostrar() {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const { data } = await productoService.getProductoModo(this.token)
      data.data.forEach(element => this.optionsMostrar.push({
        value: element.id,
        text: element.modoMostrar,
      }))

      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async obtenerAutenticacion() {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const { data } = await productoService.getProductoAutenticacion(this.token)
      data.data.forEach(element => this.optionsAutenticacion.push({
        value: element.value,
        text: element.text,
      }))

      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async obtenerFormularioCantadas() {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const { data } = await productoService.getProductoFormularioCantadas(this.token)
      data.data.forEach(element => this.optionsFormulario.push({
        value: element.value,
        text: element.text,
      }))

      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async obtenerRol() {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const responseRol = await rolService.postRolData(this.token, this.userData.companyid)
      if (responseRol.data.data.length > 0) {
        responseRol.data.data.forEach(element => this.optionsRol.push({
          value: element.id,
          text: element.nombre,
        }))
      }
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async verQR() {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const response = await usuarioService.postQrData(this.token, this.formUsuario.id)

      this.$swal({
        imageUrl: response.data,
        text: 'Codigo QR',
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Cerrar',
        customClass: {
          cancelButton: 'btn btn-danger',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
      })
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async verVuina() {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const response = await usuarioService.postUrlData(this.token, this.formUsuario.id)

      // URL a la que quieres dirigir la ventana emergente
      const url = response.data.data

      // Especifica el tamaño y otras propiedades de la ventana emergente
      const width = 500
      const height = window.innerHeight
      const left = (window.innerWidth - width) / 2
      const top = 0
      const options = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`

      // Abre la ventana emergente con las opciones especificadas
      window.open(url, 'popupWindow', options)
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async reenviarQR() {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const response = await usuarioService.postReenviarQrData(this.token, this.formUsuario.id)

      this.$swal({
        title: 'Confirmación',
        text: response.data.message,
        icon: 'info',
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Cerrar',
        customClass: {
          cancelButton: 'btn btn-danger',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
      })
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async obtenerEmpresa() {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const responseEmpresa = await empresaService.postEmpresaData(this.token, this.userData.id)
      if (responseEmpresa.data.data.length > 0) {
        this.itemsEmpresa = responseEmpresa.data.data
      }
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async onEmpresaSelect(items) {
      this.tabIndex = 0
      this.idEmpresa = items[0].id
      this.empresa.nombre = items[0].name
      this.empresa.telefono = items[0].telfcontacto
      this.empresa.cantidadMaxima = items[0].maxLicencias
      this.empresa.licenciaUso = items[0].usoLicencias
      await this.obtenerArea()
      await this.obtenerUsuarioNoAsignados()
      await this.obtenerUsuarioAsignados()
    },
    async obtenerArea() {
      this.$store.commit('app/UPDATE_MESSAGE', 'Obteniendo informacion de la empresa.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const responseArea = await areaService.postAreaData(this.token, this.idEmpresa)
      if (responseArea.data.data.length > 0) {
        this.itemsArea = responseArea.data.data
      }
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async obtenerUsuarioNoAsignados() {
      this.$store.commit('app/UPDATE_MESSAGE', 'Obteniendo informacion de la empresa.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const responseUsuarios = await areaService.postAreaUsuarioNoAsignadosData(this.token, this.idEmpresa)
      if (responseUsuarios.data.data.length > 0) {
        this.itemsNoAdministrador = responseUsuarios.data.data
      }
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async obtenerUsuarioAsignados() {
      this.$store.commit('app/UPDATE_MESSAGE', 'Obteniendo informacion de la empresa.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const responseUsuarios = await areaService.postAreaUsuarioAsignadoData(this.token, this.idEmpresa)
      if (responseUsuarios.data.data.length > 0) {
        this.itemsAdministrador = responseUsuarios.data.data
      }
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async areaAgregarUsuario(id) {
      this.$store.commit('app/UPDATE_MESSAGE', 'Obteniendo informacion de la empresa.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      await areaService.postAreaUsuarioAgregarNoAsignadosData(this.token, {
        empresa: this.idEmpresa,
        usuario: id,
      })
      this.$store.commit('app/UPDATE_ISLOADING', false)
      await this.obtenerAutenticacion()
      await this.obtenerFormularioCantadas()
    },
    async areaEliminarUsuario(id) {
      this.$store.commit('app/UPDATE_MESSAGE', 'Obteniendo informacion de la empresa.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      await areaService.postAreaUsuarioEliminarAsignadosData(this.token, {
        empresa: this.idEmpresa,
        usuario: id,
      })
      this.$store.commit('app/UPDATE_ISLOADING', false)
      await this.obtenerAutenticacion()
      await this.obtenerFormularioCantadas()
    },
    async modalArea(id) {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      if (id === 0) {
        this.tituloArea = 'Agregar area'
        this.formArea = {
          id: 0,
          nombre: '',
          estado: null,
          empresa: this.idEmpresa,
        }
      } else {
        this.tituloArea = 'Modificar area'
        const data = this.itemsArea.find(element => element.id === id)
        this.formArea = {
          id: data.id,
          nombre: data.name,
          estado: data.estado,
          empresa: this.idEmpresa,
        }
      }
      this.popupAgregarArea = true
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async eliminarArea(id) {
      this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      try {
        await areaService.postAreaDelete(this.token, id)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Area eliminada con éxito.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
        setTimeout(() => {
          this.obtenerArea()
        }, 500)
      } catch (error) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Ocurrio un error, por favor intentar nuevamente.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
      } finally {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    async limpiarArea() {
      this.$refs.filterArea.reset()
      this.formArea = {
        id: 0,
        nombre: '',
        estado: null,
        empresa: this.idEmpresa,
      }
    },
    async registrarArea() {
      const success = await this.$refs.filterArea.validate()
      if (success) {
        this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
        this.$store.commit('app/UPDATE_ISLOADING', true)
        try {
          if (this.formArea.id === 0) {
            await areaService.postAreaInsert(this.token, this.formArea)
          } else {
            await areaService.postAreaUpdate(this.token, this.formArea)
          }
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Registro satisfactorio.',
              },
            },
            {
              position: 'bottom-center',
              closeOnClick: true,
            },
          )
          this.popupAgregarArea = false
          setTimeout(() => {
            this.obtenerArea()
          }, 1000)
        } catch (error) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Ocurrio un error, por favor intentar nuevamente.',
              },
            },
            {
              position: 'bottom-center',
              closeOnClick: true,
            },
          )
        } finally {
          this.$store.commit('app/UPDATE_ISLOADING', false)
        }
      }
    },
    async obtenerCampana(id, modal) {
      this.idArea = id
      this.$store.commit('app/UPDATE_ISLOADING', true)

      const responseCampana = await campanaService.postCampanaData(this.token, id)
      this.itemsCampana = responseCampana.data.data
      console.log(responseCampana.data)
      if (modal) {
        this.popupCampana = true
      }
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async eliminarCampana(id) {
      this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      try {
        await campanaService.postCampanaDelete(this.token, id)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Campaña eliminada con éxito.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
        setTimeout(() => {
          this.obtenerCampana(this.idArea, false)
        }, 500)
      } catch (error) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Ocurrio un error, por favor intentar nuevamente.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
      } finally {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    async modalCampana(id) {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      if (id === 0) {
        this.tituloCampana = 'Agregar campaña'
        this.formCampana = {
          id: 0,
          reinicio: null,
          nombre: '',
          estado: null,
          area: this.idArea,
          mostrar: null,
          totalProducto: '',
          trivia: '0',
          objetivoDiario: '0',
          chatSoporte: '0',
          avanceComisiones: '0',
          numeroSoporte: '',
          urlChat: '',
          urlReporteSupervisor: '',
          urlReporteAdministrador: '',
          autenticacion: 'BASE',
          formularioCantadas: null,
          poppup: {
            id: 0,
            imagen: '',
            imagenFile: null,
            mensaje: '',
            fechaIni: '',
            fechaFin: '',
            url: '',
            estado: null,
          },
          producto: '',
          estadoProducto: 0,
          productos: [],
          vuinaCoins: {
            vigencia: 0,
            resetear: null,
          },
          mapa: [],
        }
      } else {
        const currentDate = new Date()
        this.tabMapa = currentDate.getMonth()

        const data = this.itemsCampana.find(element => element.id === id)

        const mapaData = await this.obtenerMapa(data.id)
        const dadoData = await this.obtenerDadoFeria(data.id)
        const ruletaData = await this.obtenerRuletaFeria(data.id)

        this.tituloCampana = `Modificar campaña ${data.name.toLowerCase()}`
        this.formCampana = {
          id: data.id,
          reinicio: data.reinicio,
          nombre: data.name,
          estado: data.estado,
          area: this.idArea,
          mostrar: data.mostrar,
          totalProducto: data.etiquetaAccionReemplazo,
          trivia: data.trivia,
          objetivoDiario: data.objetivoDiario,
          chatSoporte: data.chatSoporte,
          avanceComisiones: data.avanceComisiones,
          numeroSoporte: data.numeroSoporte,
          urlChat: data.urlChatSoporte,
          urlReporteSupervisor: data.urlReporte,
          urlReporteAdministrador: data.urlReporteAdmin,
          autenticacion: data.autenticacion,
          formularioCantadas: data.formCantadas,
          poppup: (data.poppup === null) ? {
            id: 0,
            imagen: '',
            imagenFile: null,
            mensaje: '',
            fechaIni: '',
            fechaFin: '',
            url: '',
            estado: null,
          } : {
            id: data.poppup.id,
            imagen: data.poppup.imagen,
            imagenFile: null,
            mensaje: data.poppup.mensaje,
            fechaIni: data.poppup.fechaIni,
            fechaFin: data.poppup.fechaFin,
            url: data.poppup.url,
            estado: data.poppup.activo,
          },
          producto: '',
          estadoProducto: 0,
          productos: data.productos,
          vuinaCoins: {
            vigencia: data.vigenciaMonedas,
            resetear: (data.vuinaCoins !== null) ? data.vuinaCoins.fechaResetMonedas : null,
          },
          mapa: mapaData,
        }

        this.statusDado = dadoData.dadoActivo
        this.dado.costo = dadoData.dadoCosto
        this.dado.premio1 = dadoData.dadoUno
        this.dado.premio2 = dadoData.dadoDos
        this.dado.premio3 = dadoData.dadoTres
        this.dado.premio4 = dadoData.dadoCuatro
        this.dado.premio5 = dadoData.dadoCinco
        this.dado.premio6 = dadoData.dadoSeis
        this.dado.stock1 = dadoData.dadoStockUno
        this.dado.stock2 = dadoData.dadoStockDos
        this.dado.stock3 = dadoData.dadoStockTres
        this.dado.stock4 = dadoData.dadoStockCuatro
        this.dado.stock5 = dadoData.dadoStockCinco
        this.dado.stock6 = dadoData.dadoStockSeis
        this.dado.peso1 = dadoData.dadoPesoUno
        this.dado.peso2 = dadoData.dadoPesoDos
        this.dado.peso3 = dadoData.dadoPesoTres
        this.dado.peso4 = dadoData.dadoPesoCuatro
        this.dado.peso5 = dadoData.dadoPesoCinco
        this.dado.peso6 = dadoData.dadoPesoSeis
        this.statusRuleta = ruletaData.ruletaActivo
        this.ruleta.costo = ruletaData.ruletaCosto
        this.ruleta.premio1 = ruletaData.ruletaUno
        this.ruleta.premio2 = ruletaData.ruletaDos
        this.ruleta.premio3 = ruletaData.ruletaTres
        this.ruleta.premio4 = ruletaData.ruletaCuatro
        this.ruleta.premio5 = ruletaData.ruletaCinco
        this.ruleta.premio6 = ruletaData.ruletaSeis
        this.ruleta.stock1 = ruletaData.ruletaStockUno
        this.ruleta.stock2 = ruletaData.ruletaStockDos
        this.ruleta.stock3 = ruletaData.ruletaStockTres
        this.ruleta.stock4 = ruletaData.ruletaStockCuatro
        this.ruleta.stock5 = ruletaData.ruletaStockCinco
        this.ruleta.stock6 = ruletaData.ruletaStockSeis
        this.ruleta.peso1 = ruletaData.ruletaPesoUno
        this.ruleta.peso2 = ruletaData.ruletaPesoDos
        this.ruleta.peso3 = ruletaData.ruletaPesoTres
        this.ruleta.peso4 = ruletaData.ruletaPesoCuatro
        this.ruleta.peso5 = ruletaData.ruletaPesoCinco
        this.ruleta.peso6 = ruletaData.ruletaPesoSeis
      }
      this.popupAgregarCampana = true
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async obtenerMapa(id) {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const { data } = await mapaService.postMapaData(this.token, id)
      this.$store.commit('app/UPDATE_ISLOADING', false)
      return data.data
    },
    async obtenerDadoFeria(id) {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const { data } = await feriaService.postDadoData(this.token, id)
      this.$store.commit('app/UPDATE_ISLOADING', false)
      return data.data
    },
    async obtenerRuletaFeria(id) {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const { data } = await feriaService.postRuletaData(this.token, id)
      this.$store.commit('app/UPDATE_ISLOADING', false)
      return data.data
    },
    async limpiarCampana() {
      this.$refs.filterCampana.reset()
      this.formCampana = {
        id: 0,
        reinicio: null,
        nombre: '',
        estado: null,
        area: this.idArea,
        mostrar: null,
        totalProducto: '',
        trivia: '0',
        objetivoDiario: '0',
        chatSoporte: '0',
        avanceComisiones: '0',
        numeroSoporte: '',
        urlChat: '',
        urlReporteSupervisor: '',
        urlReporteAdministrador: '',
        autenticacion: null,
        formularioCantadas: null,
        poppup: {
          id: 0,
          imagen: '',
          imagenFile: null,
          mensaje: '',
          fechaIni: '',
          fechaFin: '',
          url: '',
          estado: null,
        },
        producto: '',
        estadoProducto: 0,
        productos: [],
        vuinaCoins: {
          vigencia: 0,
          resetear: null,
        },
        mapa: {
          supervisorAlto: '',
          supervisorAncho: '',
          supervisorDeltaX: '',
          supervisorDeltaY: '',
          supervisorPuntos: '',
          supervisorMapa: null,
          supervisorImagen: '',
          agenteAlto: '',
          agenteAncho: '',
          agenteDeltaX: '',
          agenteDeltaY: '',
          agentePuntos: '',
          agenteMapa: null,
          agenteImagen: '',
        },
      }
    },
    async registrarCampana() {
      const success = await this.$refs.filterCampana.validate()
      if (success) {
        this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
        this.$store.commit('app/UPDATE_ISLOADING', true)
        try {
          if (this.formCampana.id === 0) {
            await campanaService.postCampanaInsert(this.token, this.formCampana)
          } else {
            await campanaService.postCampanaUpdate(this.token, this.formCampana)
          }
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Registro satisfactorio.',
              },
            },
            {
              position: 'bottom-center',
              closeOnClick: true,
            },
          )
          this.popupAgregarCampana = false
          setTimeout(() => {
            this.obtenerCampana(this.idArea, false)
          }, 1000)
        } catch (error) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Ocurrio un error, por favor intentar nuevamente.',
              },
            },
            {
              position: 'bottom-center',
              closeOnClick: true,
            },
          )
        } finally {
          this.$store.commit('app/UPDATE_ISLOADING', false)
        }
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Error en la validación.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
      }
    },
    async campanaActualizarPopup() {
      this.$store.commit('app/UPDATE_MESSAGE', 'Actualizando estado popup .')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      try {
        await campanaService.postCampanaActualizarEstadoPopup(this.token, {
          campana: this.formCampana.id,
          estado: this.formCampana.poppup.estado,
        })
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Registro satisfactorio.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
      } catch (error) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Ocurrio un error, por favor intentar nuevamente.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
      } finally {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    async campanaAgregarPopup() {
      this.$store.commit('app/UPDATE_MESSAGE', 'Actualizando estado popup .')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      try {
        const data = new FormData()
        data.append('campana', this.formCampana.id)
        data.append('mensaje', this.formCampana.poppup.mensaje)
        data.append('fechaIni', this.formCampana.poppup.fechaIni)
        data.append('fechaFin', this.formCampana.poppup.fechaFin)
        data.append('url', this.formCampana.poppup.url)
        data.append('imagen', this.$refs.imagenFile.files[0])

        const dataResponse = await campanaService.postCampanaInsertarPopup(this.token, data)
        this.formCampana.poppup = dataResponse.data.data

        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Registro satisfactorio.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
      } catch (error) {
        console.log(error)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Ocurrio un error, por favor intentar nuevamente.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
      } finally {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    async campanaAgregarProducto() {
      this.$store.commit('app/UPDATE_MESSAGE', 'Agregando producto.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      try {
        if (this.formCampana.estadoProducto === 0) {
          const { data } = await campanaService.postCampanaInsertProducto(this.token, this.formCampana)

          this.formCampana.productos.push({
            id: data.data.data.id,
            producto: data.data.data.producto,
          })
        } else {
          await campanaService.postCampanaUpdateProducto(this.token, {
            id: this.formCampana.estadoProducto,
            producto: this.formCampana.producto,
          })
          const result = this.formCampana.productos.findIndex(({ id }) => id === this.formCampana.estadoProducto)
          console.log(result)
          if (result >= 0) {
            this.formCampana.productos[result].producto = this.formCampana.producto
          }
        }
        this.formCampana.estadoProducto = 0
        this.formCampana.producto = ''
      } catch (error) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Ocurrio un error, por favor intentar nuevamente.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
      } finally {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    async campanaEliminarProducto(item) {
      this.$store.commit('app/UPDATE_MESSAGE', 'Eliminando producto.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      try {
        await campanaService.postCampanaDeleteProducto(this.token, item)
        const result = this.formCampana.productos.findIndex(({ id }) => id === item.id)
        if (result >= 0) {
          this.formCampana.productos.splice(result, 1)
        }
      } catch (error) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Ocurrio un error, por favor intentar nuevamente.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
      } finally {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    async campanaActualizarProducto(item) {
      this.formCampana.estadoProducto = item.id
      this.formCampana.producto = item.producto
    },
    async campanaProceder() {
      const fechaOriginal = new Date(this.formCampana.vuinaCoins.resetear)

      // Obtener componentes de la fecha
      const dia = (fechaOriginal.getDate() < 10) ? `0${fechaOriginal.getDate()}` : fechaOriginal.getDate()
      const mes = (fechaOriginal.getMonth() + 1 < 10) ? `0${fechaOriginal.getMonth() + 1}` : fechaOriginal.getMonth() + 1
      const año = fechaOriginal.getFullYear()

      // Formatear la cadena en el formato deseado (mes dia año)
      const fechaFormateada = `${dia}-${mes}-${año}`

      this.$swal({
        title: 'Confirmación',
        text: `Esta seguro que desea borrar todas las Vuina Coins hasta la fecha ${fechaFormateada}`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-2',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
      }).then(async result => {
        if (result.isConfirmed) {
          this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
          this.$store.commit('app/UPDATE_ISLOADING', true)
          try {
            await campanaService.postCampanaProceder(this.token, {
              campana: this.formCampana.id,
              fecha: `${año}-${mes}-${dia}`,
              usuario: this.userData.id,
            })
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Notificación',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: `Registro satisfactorio, a partir del dia  ${año}-${mes}-${dia} se hara efectivo el reseteo.`,
                },
              },
              {
                position: 'bottom-center',
                closeOnClick: true,
              },
            )
          } catch (error) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Notificación',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Ocurrio un error, por favor intentar nuevamente.',
                },
              },
              {
                position: 'bottom-center',
                closeOnClick: true,
              },
            )
          } finally {
            this.$store.commit('app/UPDATE_ISLOADING', false)
          }
        }
      })
    },
    async campanaCancelarBorrado() {
      this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      try {
        await campanaService.postCampanaCancelarBorrado(this.token, {
          campana: this.formCampana.id,
          usuario: this.userData.id,
        })
        this.formCampana.vuinaCoins.resetear = null
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Registro satisfactorio.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
      } catch (error) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Ocurrio un error, por favor intentar nuevamente.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
      } finally {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    async obtenerUsuario(id, modal) {
      this.idArea = id
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const responseUsuario = await usuarioService.postUsuarioData(this.token, 'AREA', id)
      this.itemsUsuario = responseUsuario.data.data
      if (modal) {
        this.popupUsuario = true
      }

      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async eliminarUsuario(id) {
      this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      try {
        await usuarioService.postUsuarioDelete(this.token, id)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Usuario eliminado con éxito.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
        setTimeout(() => {
          this.obtenerUsuario(this.idArea, false)
        }, 500)
      } catch (error) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Ocurrio un error, por favor intentar nuevamente.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
      } finally {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    async modalClaveUsuario(id) {
      this.$swal({
        title: 'Confirmación',
        text: 'Esta seguro(a) de actualizar la contraseña?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-2',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
      }).then(async result => {
        if (result.isConfirmed) {
          this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
          this.$store.commit('app/UPDATE_ISLOADING', true)
          try {
            const response = await usuarioService.postUsuarioClave(this.token, id)

            this.$swal({
              title: 'Mensaje',
              text: response.data.message,
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: 'Si',
              cancelButtonText: 'No',
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger ml-2',
              },
              showClass: {
                popup: 'animate__animated animate__bounceIn',
              },
            })

            setTimeout(() => {
              this.obtenerUsuario(this.idArea, false)
            }, 1000)
          } catch (error) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Notificación',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Ocurrio un error, por favor intentar nuevamente.',
                },
              },
              {
                position: 'bottom-center',
                closeOnClick: true,
              },
            )
          } finally {
            this.$store.commit('app/UPDATE_ISLOADING', false)
          }
        }
      })
    },
    async modalUsuario(id) {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      this.optionsCampana = [
        { value: null, text: 'Seleccionar campaña' },
      ]
      this.optionsPerfil = [
        { value: null, text: 'Seleccionar perfil' },
      ]
      const responseCampana = await campanaService.postCampanaData(this.token, this.idArea)
      responseCampana.data.data.forEach(element => this.optionsCampana.push({
        value: element.id,
        text: element.name,
      }))
      const responsePerfil = await perfilService.postPerfilData(this.token, this.idEmpresa)
      responsePerfil.data.data.forEach(element => this.optionsPerfil.push({
        value: element.id,
        text: element.perfil,
      }))
      if (id === 0) {
        this.tituloUsuario = 'Agregar usuario'
        this.formUsuario = {
          id: 0,
          tipo: null,
          numero: '',
          nombre: '',
          apellidoPaterno: '',
          apellidoMaterno: '',
          usuario: '',
          nickname: '',
          perfil: null,
          cumpleanos: '',
          email: '',
          estado: null,
          campana: null,
          supervisor: '',
          avatar: null,
          clave: '',
          rol: null,
        }
      } else {
        this.tituloUsuario = 'Modificar usuario'
        const data = this.itemsUsuario.find(element => element.id === id)
        this.formUsuario = {
          id: data.id,
          tipo: data.tipoDocumento,
          numero: data.numeroDocumento,
          username: data.username,
          nickname: data.nickname,
          perfil: data.perfil,
          nombre: data.nombre,
          apellidoPaterno: data.apellidoPaterno,
          apellidoMaterno: data.apellidoMaterno,
          email: data.email,
          supervisor: data.supervisor,
          estado: data.activo,
          cumpleanos: data.dateBirthday,
          campana: data.campaignid,
          avatar: JSON.parse(data.avatarUri),
          clave: '',
          rol: data.roles,
        }
      }
      this.popupAgregarUsuario = true
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async limpiarUsuario() {
      this.$refs.filterUsuario.reset()
      this.formUsuario = {
        id: 0,
        tipo: null,
        numero: '',
        nombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        usuario: '',
        nickname: '',
        perfil: null,
        cumpleanos: '',
        email: '',
        estado: null,
        campana: '',
        supervisor: '',
        clave: '',
        rol: null,
      }
    },
    async registrarUsuario() {
      const success = await this.$refs.filterUsuario.validate()
      if (success) {
        this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
        this.$store.commit('app/UPDATE_ISLOADING', true)
        try {
          if (this.formUsuario.id === 0) {
            await usuarioService.postUsuarioInsert(this.token, this.formUsuario)
          } else {
            await usuarioService.postUsuarioUpdate(this.token, this.formUsuario)
          }
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Registro satisfactorio.',
              },
            },
            {
              position: 'bottom-center',
              closeOnClick: true,
            },
          )
          this.popupAgregarUsuario = false
          setTimeout(() => {
            this.obtenerUsuario(this.idArea, false)
          }, 1000)
        } catch (error) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Ocurrio un error, por favor intentar nuevamente.',
              },
            },
            {
              position: 'bottom-center',
              closeOnClick: true,
            },
          )
        } finally {
          this.$store.commit('app/UPDATE_ISLOADING', false)
        }
      }
    },

    async obtenerMetrica(id) {
      this.idCampana = id
      this.$store.commit('app/UPDATE_ISLOADING', true)

      const responseMetrica = await metricaService.postMetricaData(this.token, id)
      this.itemsMetrica = responseMetrica.data.data
      console.log(responseMetrica.data)
      this.popupMetrica = true
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async eliminarMetrica(id) {
      this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      try {
        await metricaService.postMetricaDelete(this.token, id)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Métrica eliminada con éxito.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
        setTimeout(() => {
          this.obtenerMetrica(this.idCampana, false)
        }, 500)
      } catch (error) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Ocurrio un error, por favor intentar nuevamente.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
      } finally {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    async modalMetrica(id) {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      if (id === 0) {
        this.tituloMetrica = 'Agregar métrica'
        this.formMetrica = {
          id: 0,
          nombre: '',
          campana: this.idCampana,
          indicador: '',
          objetivo: '',
          tipo: null,
          cumplimiento: null,
          status: null,
          punto: '1',
          multiplicador: '1',
          inferior: null,
          superior: null,
          control: '0',
          estado: '1',
        }
      } else {
        this.tituloMetrica = 'Modificar métrica'
        const data = this.itemsMetrica.find(element => element.id === id)
        this.formMetrica = {
          id: data.id,
          nombre: data.name,
          campana: this.idCampana,
          indicador: data.indicatorName,
          objetivo: data.targetValue,
          tipo: data.dataType,
          cumplimiento: data.compliance,
          status: data.status,
          punto: '1',
          multiplicador: '1',
          inferior: null,
          superior: null,
          control: '0',
          estado: '1',
        }
      }
      this.popupAgregarMetrica = true
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },

    async limpiarMetrica() {
      this.$refs.filterMetrica.reset()
      this.formMetrica = {
        id: 0,
        nombre: '',
        campana: this.idCampana,
        indicador: '',
        objetivo: '',
        tipo: null,
        cumplimiento: null,
        status: null,
        punto: '',
        multiplicador: '',
        inferior: '',
        superior: '',
        control: '',
      }
    },
    async registrarMetrica() {
      const success = await this.$refs.filterMetrica.validate()
      if (success) {
        this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
        this.$store.commit('app/UPDATE_ISLOADING', true)
        try {
          if (this.formMetrica.id === 0) {
            await metricaService.postMetricaInsert(this.token, this.formMetrica)
          } else {
            await metricaService.postMetricaUpdate(this.token, this.formMetrica)
          }
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Registro satisfactorio.',
              },
            },
            {
              position: 'bottom-center',
              closeOnClick: true,
            },
          )
          this.popupAgregarMetrica = false
          setTimeout(() => {
            this.obtenerMetrica(this.idCampana, false)
          }, 1000)
        } catch (error) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Ocurrio un error, por favor intentar nuevamente.',
              },
            },
            {
              position: 'bottom-center',
              closeOnClick: true,
            },
          )
        } finally {
          this.$store.commit('app/UPDATE_ISLOADING', false)
        }
      }
    },
  },
}
</script>

<style scoped>
.custom-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.custom-checkbox {
  margin-right: 10px; /* Ajusta el margen según sea necesario */
}
</style>
