import axios from '@axios'
import store from '@/store'

export default {
  async postDadoUpdate(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/feria/updateDadoEstadoWeb `,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        campana: data.id,
        estado: data.estado,
      },
    })
    return response
  },
  async postRuletaUpdate(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/feria/updateRuletaEstadoWeb `,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        campana: data.id,
        estado: data.estado,
      },
    })
    return response
  },
  async postDadoData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/feria/getDadoItemsWeb `,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        campana: id,
      },
    })
    return response
  },
  async postRuletaData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/feria/getRuletaItemsWeb `,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        campana: id,
      },
    })
    return response
  },
  async postFeriaRegistrar(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/feria/updateDadoRuletaItemsWeb`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    return response
  },
  async postFeriaActualizar(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/feria/nuevoSetDadoRuletaItemsWeb`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      data: {
        campana: id,
      },
    })
    return response
  },
}
